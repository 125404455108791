import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { LoadingComponent } from '../components/loading/loading.component';

//TODO: the element can just be in main component so it is all not needed

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isInProgress: boolean = false;
  private componentRef?: ComponentRef<LoadingComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) { }

  start(): void {
    if (this.isInProgress) {
      return;
    }

    this.isInProgress = true;

    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent).create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);
  
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);
    document.body.classList.add('noscroll');
  }

  finish(): void {
    if (!this.isInProgress) {
      return;
    }

    this.isInProgress = false;
    
    this.appRef.detachView(this.componentRef!.hostView);
    this.componentRef!.destroy();
    document.body.classList.remove('noscroll');
  }
}
