<div class="preparation-container d-flex flex-column align-items-center text-center">
    <div class="position-relative">
        <mat-spinner diameter="128" mode="determinate" [value]="value"></mat-spinner>
        
        <span class="main-color progress-label">{{value}}%</span>
    </div>
    <div class="title">We are crafting your <span class="main-color">listening and learning</span> experience...</div>
    <div class="list">
        <div class="item" *ngIf="value > 10">Responses are being analysed <span class="done">✅</span></div>
        <div class="item" *ngIf="value > 35">Selecting the voices <span class="done">✅</span></div>
        <div class="item" *ngIf="value > 80">Creating account for you <span class="done">✅</span></div>
    </div>
</div>
