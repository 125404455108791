import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var fbq: any;

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor() {
    if (!environment.production) {
      (window as any).fbq = ((_: string, b: string) => console.log(`Simulating fb pixel track: ${b}`));
    }
  }

  track(event: 'first_open' | 'subscribe' | 'paywall_opened' | 'checkout_opened' | 'page_view' | 'signed_in'): void {
    const eventID = `${event}_${Date.now()}`; //this is used to dedublicate events sent from server & browser

    switch (event) {
      case 'first_open':
        fbq('track', 'Lead', null, { eventID });
        break;
      case 'subscribe':
        fbq('track', 'Purchase', null, { eventID });
        break;
      case 'paywall_opened':
        fbq('track', 'ViewContent', null, { eventID });
        break;
      case 'checkout_opened':
        fbq('track', 'InitiateCheckout', null, { eventID });
        break;
      case 'page_view':
        fbq('track', 'PageView', null, { eventID });
        break;
      case 'signed_in':
        fbq('track', 'CompleteRegistration', null, { eventID });
        break;
      default:
        break;
    }
  }
}
