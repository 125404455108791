<div class="d-flex flex-column profile-container">
    <mat-card class="mb-25 personal-info-card tagus-card col-12" [class.component-dark-theme]="themeService.isDark()">
        <mat-card-header class="border-bottom">
            <h5 class="mb-0">Personal Information</h5>
        </mat-card-header>
        <mat-card-content>
            <!-- <span class="d-block fw-semibold mb-5">About Me:</span> -->
            <ul class="list ps-0 mb-0">
                <li>
                    <span class="fw-semibold d-inline-block">Full Name:</span>
                    <span class="gray-color">{{name}}</span>
                </li>
                <li>
                    <span class="fw-semibold d-inline-block">Email:</span>
                    <span class="gray-color">{{email}}</span>
                </li>
                <li *ngIf="isSubscribed">
                    <span class="fw-semibold d-inline-block">Next Billing Cycle:</span>
                    <span class="gray-color">{{subscriptionDate | date}}</span>
                </li>
            </ul>
        </mat-card-content>
    </mat-card>
    <mat-card class="mb-30 personal-info-card tagus-card col-12" [class.component-dark-theme]="themeService.isDark()">
        <mat-card-header class="border-bottom">
            <h5 class="mb-0">Links</h5>
        </mat-card-header>
        <mat-card-content>
            <!-- <span class="d-block fw-semibold mb-5">About Me:</span> -->
            <ul class="list ps-0 mb-0">
                <li>
                    <a href="https://www.getpeech.com/chrome-extension" class="fw-semibold d-inline-block main-color">Chrome Extension for Saving the Articles from Desktop Web</a>
                </li>
                <li>
                    <a href="https://apps.apple.com/app/apple-store/id1429704005?pt=119230880&ct=web-onboarding&mt=8" class="fw-semibold d-inline-block main-color">iOS app for adding and listening</a>
                </li>
                <li *ngIf="isSubscribed">
                    <div (click)="cancelSubscription()" class="fw-semibold d-inline-block gray-color cursor-pointer">Cancel the Subscription</div>
                </li>
            </ul>
        </mat-card-content>
    </mat-card>
</div>