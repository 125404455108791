import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { catchError, skip, switchMap, take, tap, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.token$.pipe(
    switchMap(token => {
      if (token) {
        return next(req.clone({
          headers: req.headers.set('Authorization', token)
        }));
      }

      return next(req);
    }),
    catchError(error => {
      if (error.status === 401) {
        return auth.user$.pipe(
          switchMap(user => {
            if (user) {
              return auth.token$
                .pipe(
                  skip(1),
                  tap(() => auth.updateToken()),
                  take(1),
                  switchMap(refreshedToken => next(req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${refreshedToken}`)
                  }))),
                  catchError(error => {
                    if (error.status === 401) {
                      auth.logout();
                      router.navigate(['/authentication/login']);
                    }
                    return throwError(() => new Error(error));
                  })
                );
            } else {
              // Redirect to login
              router.navigate(['/authentication/login']);

              return throwError(() => new Error(error));
            }
          })
        );
      }

      return throwError(() => new Error(error));
    })
  );
};
