import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, catchError, finalize, map, take, tap } from 'rxjs';
import { AuthProviderType, AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';

const loginSources = {
  extension: 'chrome-extension'
};

@Component({
  selector: 'app-peech-login',
  templateUrl: './peech-login.component.html',
  styleUrls: ['./peech-login.component.scss']
})
export class PeechLoginComponent implements OnInit {
  private destination: string = '';

  constructor(
    private authService: AuthService, 
    private router: Router, 
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    ) {}

  ngOnInit(): void {
    const source = this.route.snapshot.queryParams['source'];

    switch (source) {
      case loginSources.extension:
        this.destination = 'peech-chrome-extension';
        break;
      default:
        this.destination = 'unknown';
        console.log('Not clear where you came from');
    }
  }

  // emailClicked(): void {
  //   this.router.navigate(['email'], { queryParamsHandling: 'merge' });
  // }

  loginClicked(provider: AuthProviderType): void {
    this.loadingService.start();

    this.authService.login(provider)
      .pipe(
        take(1),
        finalize(() => this.loadingService.finish()),
        map(user => user.refreshToken),
        tap(token => this.router.navigate(['success'], {
          queryParams: {
            destination: this.destination,
            token,
          }
        })),
        catchError((error) => { 
          alert('Sorry. Failed to Sign You In. Please try again.');

          console.log(error);

          return EMPTY;
        }))
      .subscribe();
  }
}
