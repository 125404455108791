<div class="email-container d-flex flex-column align-items-center">
    <div class="title mt-20 ms-20 me-20">Sign In to existing account</div>
    <form [formGroup]="signIn" class="bg-white border-radius pt-20 ps-20 pe-20 mt-20">
        <div class="tagus-form-group without-icon">
            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
            <mat-form-field appearance="fill">
                <mat-label>Enter email address</mat-label>
                <input matInput placeholder="harry@hogwarts.com" formControlName="email">
                <mat-error *ngIf="signIn.controls.email.invalid">Email address is invalid</mat-error>
            </mat-form-field>
        </div>
        <div class="tagus-form-group without-icon">
            <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
            <mat-form-field appearance="fill">
                <mat-label>Enter your password</mat-label>
                <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="signIn.controls.password.invalid">Password is required</mat-error>
            </mat-form-field>
        </div>
    </form>
    <mat-error class="mt-20" *ngIf="signInError">{{signInError}}</mat-error>   
    <div class="footer">
        <button mat-flat-button class="tagus fw-semibold" [disabled]="signIn.invalid" (click)="continueButtonClicked()">Continue</button>
    </div>
</div>
