import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { filter, map, switchMap, tap } from 'rxjs';

const getRedirectUrl = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  if (state.url.includes('/onboarding')) {
    return '/onboarding/login';
  }

  return '/authentication/login';
};

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.authInitialized$
    .pipe(
      filter(initialized => initialized),
      switchMap(() => auth.user$),
      map(user => !!user),
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          router.navigate([getRedirectUrl(route, state)]);
        }
      })
    );
}
