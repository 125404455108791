import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrl: './name.component.scss'
})
export class NameComponent {
  @ViewChild('nameInput') nameInput!: ElementRef;

  name = new FormControl('', [Validators.required]); //TODO: don't forget to trim it
  
  constructor(private flowService: OnboardingFlowService) {}

  enterPressed(): void {
    if (!this.name.value || this.name.invalid) {
      return;
    }

    this.nameInput.nativeElement.blur();

    this.proceed();
  }

  continueButtonClicked(): void {
    if (this.name.invalid) {
      return;
    }

    this.proceed();
  }

  proceed(): void {
    this.flowService.handleFinishedName(this.name.value!)
  }
}
