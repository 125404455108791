<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">Forgot Password?</h2>
                        <img src="assets/img/logo-icon.png" alt="logo-icon">
                    </div>
                    <p>Enter your email and we′ll send you instructions to reset your password</p>
                </div>
                <form>
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input matInput>
                            </mat-form-field>
                        </div>
                    </div>
                    <button mat-flat-button class="tagus d-block fw-semibold">Send Reset Link</button>
                    <div class="text-center back-to-login">
                        <a routerLink="/authentication/login" class="d-inline-block main-color fw-medium position-relative"><i class="flaticon-chevron-1"></i> Back to Login</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>