import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { loadStripe, PaymentMethodResult, StripeCardElement, StripeElements, StripeError } from '@stripe/stripe-js';
//import { loadScript } from '@paypal/paypal-js';
import { track } from '@amplitude/analytics-browser';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { PaymentIntentResult, Stripe } from '@stripe/stripe-js';
import { combineLatest, from, fromEvent, of, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { environment } from 'src/environments/environment';
import { ProductView } from '../checkout/checkout.component';

export interface PaymentData {
  product: ProductView;
  buttonTitle: string;
  email?: string;
  name?: string;
  stripeCustomerId?: string;
  insufficientFundsAction?: () => void;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent implements OnInit {
  //@ViewChild('paypal') paypalElement!: ElementRef;
  @ViewChild('stripeElement') stripeElement!: ElementRef;
  @ViewChild('stripeForm') stripeForm!: ElementRef;
  @ViewChild('stripeEmail') stripeEmail!: ElementRef;

  @ViewChild('expressCheckout') expressCheckoutContainer!: ElementRef;

  isLoading: boolean = true;

  email: string | undefined;
  name: string | undefined;
  product: ProductView;

  isExpressMethodsAvailable: boolean = true;
  purchaseButtonTitle = 'Continue';

  private stripeCustomerId: string | undefined;
  private clientSecret: string; //stripe
  private isSetupIntent: boolean; //stripe

  private stripeElements: StripeElements | undefined;
  private stripeLoaded$ = from(loadStripe(environment.stripePubKey));
  private card: StripeCardElement;

  private insufficientFundsAction: (() => void) | undefined;

  constructor(
    private router: Router,
    private api: ApiService,
    //private pixel: EventsService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<PaymentComponent, boolean>,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: PaymentData,
    ) {
      this.email = data.email;
      this.product = data.product;
      this.purchaseButtonTitle= data.buttonTitle;
      this.name = data.name;
      this.stripeCustomerId = data.stripeCustomerId;
      this.insufficientFundsAction = data.insufficientFundsAction;
    }

  ngOnInit(): void {
    const subscription$ = this.api.createStripeSubscription(this.product.id, this.email, this.stripeCustomerId, this.name).pipe(
      tap(({ clientSecret, isSetupIntent }) => {
        this.clientSecret = clientSecret;
        this.isSetupIntent = isSetupIntent;
      }),
    );

    combineLatest([subscription$, this.stripeLoaded$])
      .pipe(
        take(1),
        tap(([_, stripe]) => this.createMainPayment(stripe!)),
        tap(([_, stripe]) => this.createAdditionalPaymentButton(stripe!)),
        switchMap(([_, stripe]) => this.watchStripe(stripe!)),
      )
      .subscribe();
  }

  close(isSuccess: boolean) {
    this.bottomSheetRef.dismiss(isSuccess);
  }

  private createMainPayment(stripe: Stripe) {
    this.stripeElements = stripe.elements({
      clientSecret: this.clientSecret,
      appearance: {
        theme: 'stripe',
        variables: {
          borderRadius: '8px',
          fontFamily: '"Open Sans", sans-serif;'
        },
      },
      loader: 'always'
    });

    this.card = this.stripeElements?.create('card', {
      hidePostalCode: true,
      disableLink: true,
      style: {
        base: {
          lineHeight: '40px',
          fontFamily: '"Open Sans", sans-serif',
          fontSize: '16px',
        }
      }
    });
    
    this.card.mount(this.stripeElement.nativeElement);

    if (!this.email) {
      this.stripeElements?.create('linkAuthentication').mount(this.stripeEmail.nativeElement);
    }
  }

  private createAdditionalPaymentButton(stripe: Stripe) {
    const expressCheckoutElement = this.stripeElements?.create('expressCheckout', {
      paymentMethods: {
        applePay: 'always',
        googlePay: 'always',
        paypal: 'auto',
        link: 'auto',
        amazonPay: 'auto',
      },
      paymentMethodOrder: ['applePay', 'paypal', 'link', 'googlePay', 'amazonPay'],
      buttonTheme: {
        applePay: 'black',
        googlePay: 'black',
        paypal: 'gold',
      },
      layout: {
        maxRows: 2,
      },
      buttonHeight: 48,
    });

    expressCheckoutElement?.mount(this.expressCheckoutContainer.nativeElement);
    expressCheckoutElement?.on('click', (event) => {
      event.resolve({
        emailRequired: !this.email
      });
    });

    expressCheckoutElement?.on('ready', ({ availablePaymentMethods }) => {
      this.isExpressMethodsAvailable = !!availablePaymentMethods;
      this.isLoading = false; //TODO: make sure there is no better api in stripe to wait
    });

    expressCheckoutElement?.on('confirm', async (event) => {
      const { error } = await stripe.confirmPayment({
        elements: this.stripeElements,
        clientSecret: this.clientSecret,
        confirmParams: {
          return_url: `${location.origin}/result`,
        },
        redirect: 'if_required',
      });
    
      if (error) {
        this.handleError(error);
      } else {
        // Customer will be redirected to your `return_url`.
        this.goToSuccess();
      }
    });
  }

  private watchStripe(stripe: Stripe) {
    // const confirmParams = {
    //   elements: this.stripeElements!,
    //   confirmParams: {
    //     return_url: `${location.origin}/result`
    //   },
    //   redirect: 'if_required' as 'if_required'
    // };

    return fromEvent(this.stripeForm.nativeElement, 'submit')
      .pipe(
        tap((event: any) => event.preventDefault()),
        tap(() => this.loadingService.start()),
        switchMap(() => from(stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
          }))),
        //switchMap(() => this.isSetupIntent ? from(stripe.confirmSetup(confirmParams)) : from(stripe.confirmPayment(confirmParams))),
        switchMap((result: PaymentMethodResult) => {
          if (result.error) {
            this.handleError(result.error);
            return of(null);
          }

          return from(stripe.confirmCardPayment(this.clientSecret, {
            payment_method: result.paymentMethod.id
          }));
  
          //console.log((result as any).setupIntent || (result as any).paymentIntent);    
        }),
        tap((result: PaymentIntentResult | null) => {
          if (result?.error) {
            this.handleError(result.error);
            return;
          }

          if (result?.paymentIntent && result?.paymentIntent.status === 'succeeded') {
            this.goToSuccess();
          }
        }),
      );
  }

  private goToSuccess() {
    this.loadingService.finish();
    this.close(true);
    this.router.navigate(['result']);
  }

  private handleError(error: StripeError) {  
    console.log(error);

    this.loadingService.finish();

    if (error.type === 'validation_error') {
      return;
    }

    track('web_subscribe_failure', {
      error: error.code || error.message
    });

    if (error.decline_code === 'insufficient_funds' && this.insufficientFundsAction) {
      this.close(true); //attention here
      this.insufficientFundsAction();
      return;
    }

    this.stripeElements?.getElement('card')?.clear();

    //TODO: in case of insufficient funds propose discount
    this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Sorry',
        message: error.message || 'Your payment failed. Please Try Again.',
        rive: {
          fileName: 'credit_card',
          animations: ['Timeline 1'],
          ratio: 1.1 / 1,
        },
      }
    })
      .afterClosed()
      .subscribe();
  }

  // private initPayPal(): void {
  //   from(loadScript({ 'client-id': environment.payPalClientId, vault: true }))
  //     .pipe(
  //       filter(paypal => !!paypal),
  //       take(1),
  //       switchMap(paypal => from(paypal!.Buttons!({
  //         style: {
  //           label: 'paypal',
  //           color: 'gold',
  //           layout: 'horizontal',
  //           //height: 80,
  //         },
  //         createSubscription: (data, actions) => {
  //           //track('web_checkout_paypal_click');

  //           return actions.subscription.create({
  //             plan_id: this.selectedProductControl.value?.paypalPlanId || 'error',
  //             //custom_id: `${this.data.userId}`, //TODO: think about it
  //           });
  //         },
  //         onApprove: (data, actions) => {
  //           console.log('onApprove - transaction was approved, but not authorized', data, actions);
  //           //actions.order!.get().then((details: any) => {

  //           //this.bottomSheetRef.close(CheckoutResult.success);

  //           return Promise.resolve();
  //         },  
  //         onError: error => {
  //           console.log(error);
  //           //this.bottomSheetRef.close(CheckoutResult.failure);
  //         }
  //       }).render(this.paypalElement.nativeElement))),
  //     )
  //     .subscribe();
  // }

}
