import { UserReview } from "src/app/shared/user-review/user-review.component";
import { AudioTrack } from "../../shared/audio-track/audio-track.component";

export interface RiveAnimationConfig {
  fileName: string;
  animations: string[];
  ratio: number;
  backgroundImagePath?: string;
  marginTop?: string; //TODO: this is workaround because some animations have redundant space on top
}

export interface QuizOption {
    title?: string;
    emoji?: string;
    imageFilePath?: string;
    rive?: RiveAnimationConfig;
}

export interface Quiz {
    title: string;
    subTitle?: string;
    options?: QuizOption[];
    isTilesDisplay?: boolean; //in case we want to show options as tiles
    isChips?: boolean;
    imageFilePath?: string;
    rive?: RiveAnimationConfig;
    videoFilePath?: string;
    headline?: string;
    isNegative?: boolean; //this is just for headline as of now
    topIcon?: string;
    audioTracks?: AudioTrack[];
    isVoting?: boolean;
    hasSocialProof?: boolean; //it should be either social proof or review not both
    userReview?: UserReview;
    isMultiple: boolean;
    isRequired: boolean;
}

export const highlightText = (text: string, isRed: boolean = false) => {
  const className = isRed ? 'red-color' : 'main-color';
  return `<span class="${className}">${text}</span>`;
}

export const studySubjects = [
  '🏛 History',
  '💼 Business Management',
  '⚕️ Clinical Medicine',
  '🧬 Science',
  '🔬 Physics',
  '📊 Finance & Investing',
  '📣 Marketing',
  '📐 Mathematics',
  '📖 Literature',
  '💻 Computer Science',
  '⚖️ Law',
  '🧮 Economics',
  '🌍 Political Science',
  '🧪 Biology',
  '🎨 Arts',
] as const;

export const interests = [
  '💔 Dark Romance',
  '🏦 Wealth Building',
  '💆 Well-Being',
  '📖 Fan Fiction',
  '🛡 Fantasy',
  '🏋️ Fitness & Health',
  '🧠 Mental Health',
  '📰 News & Current Events',
  '❤️ Romance',
  '👪 Parenting & Family',
  '🚀 Personal Growth & Motivation',
  '📜 Religion & Spirituality',
  '🔍 Thriller & Mystery',
  '🌍 Environment & Sustainability',
] as const;

// {[key: string]: AudioTrack} 
export const audioTracks = {
  news: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/news.mp3',
    title: 'Reuters',
  },

  history: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/history.mp3',
    title: 'New History: The Cold War',
  },

  business: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/business_management.mp3',
    title: 'Harvard Business Review',
  },

  medicine: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/clinical_med.mp3',
    title: 'The Lancet',
  },

  physics: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/physics.mp3',
    title: 'Nature Physics',
  },

  darkRomance: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/dark_romance.mp3',
    title: 'Wuthering Heights',
  },

  environment: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/environment.mp3',
    title: 'National Geographic',
  },

  fanfiction: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/fanfiction.mp3',
    title: 'A03, by Harry Potter',
  },

  fantasy: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/fantasy.mp3',
    title: 'The Lord of the Rings',
  },

  finance: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/finance.mp3',
    title: 'The Wall Street Journal',
  },

  fitness: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/fitness.mp3',
    title: `Men’s Health`,
  },

  marketing: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/marketing.mp3',
    title: 'Adweek',
  },

  mentalHealth: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/mental_health.mp3',
    title: 'Psychology Today',
  },

  parenting: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/parenting.mp3',
    title: 'Parents Magazine',
  },

  personalGrowth: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/personal_growth_motivation.mp3',
    title: 'Tony Robbins',
  },

  religion: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/religious.mp3',
    title: 'John 3:16-18, Bible',
  },

  romance: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/romance.mp3',
    title: 'Bridget Jones’s Diary',
  },

  science: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/science.mp3',
    title: 'Scientific American',
  },

  thrillerMystery: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/thriller_mystery.mp3',
    title: 'The Da Vinci Code',
  },

  wealthBuilding: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/wealth_building.mp3',
    title: 'Forbes',
  },

  wellBeing: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/well_being.mp3',
    title: 'Mindful Magazine',
  },

  math: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/mathematics.mp3',
    title: 'Euclid’s Elements',
  },

  literature: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/literature.mp3',
    title: 'Shakespeare Explained',
  },

  cs: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/computer_science.mp3',
    title: 'Introduction to Algorithms',
  },

  law: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/law.mp3',
    title: 'Civil Code',
  },

  economics: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/economics.mp3',
    title: 'Principles of Economics',
  },

  politics: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/political_science.mp3',
    title: 'Democracy in America',
  },

  biology: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/biology.mp3',
    title: 'Photosynthesis',
  },

  arts: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/arts.mp3',
    title: 'The Story of Art',
  },
};

export const languageTracks = {
  en: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_en.mp3',
    title: 'English',
  },
  es: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_es.mp3',
    title: 'Spanish',
  },
  fr: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_fr.mp3',
    title: 'French',
  },
  de: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/langs/2lang_de.mp3',
    title: 'German',
  },
  it: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_it.mp3',
    title: 'Italian',
  },
  pt: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_pt.mp3',
    title: 'Portuguese',
  },
  zh: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_zh.mp3',
    title: 'Chinese',
  },
  hi: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/langs/2lang_hi.mp3',
    title: 'Hindi',
  },
};

//not used rives: confident, rocket

export const quizSteps = {
    gender: {
      title: `Select your ${highlightText('gender')}`,
      subTitle: 'Let us personalize your experience',
      options: [
        {
          title: '👱‍♂️ Male',
          rive: {
            fileName: 'simple_boy',
            animations: ['Timeline 1'],
            ratio: 1.49 / 1,
          },
        }, {
          title: '👩 Female',
          rive: {
            fileName: 'simple_grl',
            animations: ['Timeline 1'],
            ratio: 1.49 / 1,
          },
        }, {
          title: '🧓 Other'
        },
      ],
      isTilesDisplay: true,
      isMultiple: false,
      isRequired: true,
    } as Quiz,
    age: {
      title: 'What is your age?',
      options: [
        {
          title: '< 21',
          rive: {
            fileName: 'age_01',
            animations: ['Character_Loop'],
            ratio: 1.49 / 1,
          },
        }, {
          title: '21-34',
          rive: {
            fileName: 'age_02',
            animations: ['Character_Loop'],
            ratio: 1.49 / 1,
          },
        }, {
          title: '35-44',
          rive: {
            fileName: 'age_03',
            animations: ['Character_Loop'],
            ratio: 1.49 / 1,
          },
        }, {
          title: '45+',
          rive: {
            fileName: 'age_04',
            animations: ['Character_Loop'],
            ratio: 1.49 / 1,
          },
        },
      ] as QuizOption[],
      isTilesDisplay: true,
      isMultiple: false,
      isRequired: true,
    },
    amountInfo: {
      title: 'Join Over 1 Million Users Your Age Worldwide',
      subTitle: 'Discover how Peech boosts productivity and supports educational goals for students like you',
      rive: {
        fileName: 'map',
        animations: ['appear', 'both'], //["idle","female","both","male","appear"]
        ratio: 1.75 / 1,
        backgroundImagePath: '../../../../assets/onboarding/map.png',
      },
      hasSocialProof: true,
      isRequired: false,
      isMultiple: false,
    },
    level: {
      title: 'What level of study are you currently in?',
      subTitle: 'Knowing your level helps us tailor the experience to your needs',
      options: [
        '🏫 High School',
        '📘 First Two Years of University/College',
        '🎩 Final Two Years of University/College',
        '🎓 Graduate or Postgraduate',
        'I’m just browsing',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    overwhelming: {
      title: `How often do you ${highlightText('feel overwhelmed', true)} by your coursework and reading assignments?`,
      //subTitle: '',
      options: [
        '😩 Always',
        '😓 Frequently',
        '😐 Occasionally',
        '🙂 Rarely',
        '😎 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    overwhelmingInfo: {
      title: highlightText('You’re not alone!'),
      subTitle: 'Did you know that 85% of students feel overwhelmed during their studies? But don’t worry, we’re experts at helping students like you manage study overload.',
      rive: {
        fileName: 'ambivert',
        animations: ['char1', 'char2', 'blink'],
        ratio: 1.75 / 1,
      },
      isMultiple: false,
      isRequired: false,
    },
    balance: {
      title: 'Do you find it challenging to balance academics with social life, work, or personal interests?',
      //subTitle: '',
      options: [
        '⚖️ Yes, it’s a constant struggle',
        '🏃 Yes, quite often',
        '🤷 Sometimes',
        '😊 Not really',
        '💪 No, I manage well',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    distraction: {
      title: `When studying, how easily do ${highlightText('you get distracted', true)} by social media or other online activities?`,
      //subTitle: '',
      options: [
        '📱 Extremely easily',
        '💻 Very easily',
        '😅 Somewhat easily',
        '🧐 Rarely',
        '🙅 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    retention: {
      title: `Do you ever feel like you spend a lot of time reading but ${highlightText('don’t remember much?', true)}`,
      options: [
        '🤔 Yes, all the time',
        '😕 Yes, often',
        '😐 Sometimes',
        '😊 Rarely',
        '🤓 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    retentionInfo: {
      title: highlightText('Alarming Fact'),
      subTitle: 'The secret to retaining more is to combine reading and listening. 73% of students say it boosts retention. That’s why they love Peech — it helps turn reading into audio for better results.',
      rive: {
        fileName: 'boy_with_book_outside',
        animations: ['Timeline 1'],
        ratio: 1.1 / 1,
        marginTop: '-10%',
      },
      isMultiple: false,
      isRequired: false,
    },
    goal: {
      title: 'What is your main goal right now?',
      //subTitle: `We’ll help you make it happen, step by step`, //to save some vertical space
      isTilesDisplay: true,
      options: [
        {
          title: 'Crush my exams',
          imageFilePath: 'assets/onboarding/pictures/crush-my-exams.png',
        },
        {
          title: 'Free up time for yourself',
          imageFilePath: 'assets/onboarding/pictures/free-up-time-for-yourself.png',
        },
        {
          title: 'Reduce academic stress and anxiety',
          imageFilePath: 'assets/onboarding/pictures/reduce-stress-anxiety.svg',
        },
        {
          title: 'Boost my memory and comprehension',
          imageFilePath: 'assets/onboarding/pictures/boost-my-memory.png',
        },
        {
          title: 'Prepare for a successful career',
          imageFilePath: 'assets/onboarding/pictures/prepare-for-a-successful-career.png',
        },
      ],
      isMultiple: false,
      isRequired: true,
    } as Quiz,
    goalInfo: {
      title: `Great! You have just set your first goal!`,
      subTitle: `We'll do your best to help you! Peech has helped more than 4,000,000 students improve their focus, boost their comprehension, and save time`,
      rive: {
        // fileName: 'rocket',
        // animations: ['char', 'rocket'],
        fileName: 'student_group',
        animations: [],
        ratio: 1024 / 833,
        marginTop: '-20%',
      },
      isMultiple: false,
      isRequired: false,
    } as Quiz,
    anxiety: {
      title: `Do you experience ${highlightText('stress or anxiety', true)} related to academic performance?`,
      options: [
        '😰 Yes, it’s significant',
        '😟 Yes, to some extent',
        '😬 Occasionally',
        '🙂 Rarely',
        '😌 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    procrastination: {
      title: 'How often do you procrastinate on assignments or studying?',
      options: [
        '🐌 Always',
        '🐢 Frequently',
        '🐇 Sometimes',
        '🦅 Rarely',
        '🚀 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    studySubjects: {
      title: 'What are your main fields of interest or study subjects?',
      options: [
        ...studySubjects,
        '🤔 Others',
      ].map(title => ({ title })),
      isChips: true,
      isMultiple: true,
      isRequired: true,
    },
    studySubjectsInfo: {
      title: `You're on the right track!`,
      subTitle: 'Whether you\'re diving deep into learning or preparing for your future career, Peech is here to support every step of the way. Check how your studies might sound with our AI:',
      rive: {
        fileName: 'impressed',
        animations: ['char', 'skirt', 'birds', 'wings'],
        ratio: 1.75 / 1,
      },
      isMultiple: false,
      isRequired: false,
    },
    interests: {
      title: 'What are your interests outside of academics?',
      options: [
        ...interests,
        '🤔 Others',
      ].map(title => ({ title })),
      isChips: true,
      isMultiple: true,
      isRequired: true,
    },
    interestsInfo: {
      title: `You’re in Good Company! ${highlightText('Over 1.5 million students')} using Peech share similar interests`,
      subTitle: 'Stay tuned! Soon, you’ll discover how Peech helps you stay connected with what matters to you, both in and outside of academics.',
      topIcon: '🤝',
      userReview: {
        message: 'The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend.',
        name: 'Jerin Barnes',
        avatarUrl: 'assets/img/avatars/5.png',
      },
      isMultiple: false,
      isRequired: false,
    },
    afterStudyGoal: {
      title: `What is one of your ${highlightText('biggest life goals')} after finishing your studies?`,
      subTitle: 'You’re more likely to reach your goal if you have something important to aim for.',
      options: [
        '🏠 Buy a house',
        '🚗 Buy a dream car',
        '💍 A perfect wedding',
        '🌍 Travel the world',
        '💼 Start my own business',
        '👨‍👩‍👧‍👦 Build a happy family',
        //'🤔 Other',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    technology: {
      title: `How do you feel about using technology ${highlightText('to enhance your learning?')}`,
      options: [
        '🤩 Very excited and open',
        '😊 Open, but cautious',
        '😐 Neutral',
        '📘 Prefer traditional methods',
        '🙅 Avoid using technology',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    technologyInfo: {
      title: highlightText('Learning Has Evolved'),
      subTitle: 'We’re not stuck in the Middle Ages anymore, using paper books and handwritten notes. Let modern AI tools like Peech make your learning easier and more efficient. Keep up with the fast-moving world and don’t get left behind.',
      rive: {
        fileName: 'boy_with_book',
        animations: [],
        ratio: 1024 / 882,
        marginTop: '-20%',
      },
      isMultiple: false,
      isRequired: false,
    },
    audio: {
      title: `Have you tried using ${highlightText('audiobooks or podcasts')} to supplement your learning?`,
      options: [
        '🎧 Yes, regularly',
        '🎙️ Yes, occasionally',
        '👂 No, but I’m interested',
        '🤔 No, not really',
        '🚫 No, and I’m not interested',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    audioInfo: {
      title: highlightText(`Audio Learning`),
      subTitle: 'Reading can be tough and time-consuming, making it hard to stay focused. Listening is effortless and helps you retain more information. That’s why more and more people are choosing to learn through listening.',
      rive: {
        fileName: 'relax_man',
        animations: ['Timeline 1'],
        ratio: 1024 / 937,
        marginTop: '-10%',
      },
      isMultiple: false,
      isRequired: false,
    },
    eyes: {
      title: `How often do you feel ${highlightText('eye strain or tiredness', true)} from studying or using screens for too long?`,
      options: [
        '👁️ Always',
        '👀 Frequently',
        '😕 Sometimes',
        '😊 Rarely',
        '😌 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    eyesBreak: {
      title: highlightText('🌿 Give Your Eyes a Break!'),
      subTitle: 'Eye strain from long study sessions or screen time is common, but there’s an easy way to relax your eyes right now. Follow the dot with your eyes to give them a quick refresh!',
      videoFilePath: 'assets/onboarding/eyes.mp4',
      isMultiple: false,
      isRequired: false,
    },

    screenTime: {
      //TODO: think - maybe just voting with picture?
      title: `How satisfied are you with your ${highlightText('current screen time')} habits?`,
      options: [
        'Very satisfied',
        'Somewhat satisfied',
        'Neutral',
        'Somewhat dissatisfied',
        'Very dissatisfied',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    screenTimeSocialProof: {
      title: `You are not alone. There are ${highlightText('more than 3M users')} in Peech who improve their digital habits every single day`,
      topIcon: '👐',
      userReview: {
        message: 'How the perfect AI reader cut my screen time by 25% in a week',
        name: 'Robert Ashton',
        avatarUrl: 'assets/img/avatars/7.png',
        logoUrl: 'assets/onboarding/ai_writer.svg'
      } as UserReview,
      isRequired: false,
      isMultiple: false,
    },

    screenTimeQuestion: {
      title: `What is your daily average Screen Time on your phone?`,
      subTitle: `This can be checked in Screen Time section of the Settings app`,
      options: [
        '1-2 hours',
        '2-3 hours',
        '3-5 hours',
        '5-8 hours',
        '8-12 hours',
        '12+ hours',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    screenTimeResult: {
      title: `The bad news is that you will spend this much time staring at your phone:`,
      topIcon: '😵‍💫',
      isNegative: true,
      headline: '100500 hours',
      isRequired: false,
      isMultiple: false,
    },
    screenTimeImprovement: {
      title: `The good news is that Peech can help you get back `,
      subTitle: `Less screen time means more focus, better health, and time for the things you love!`,
      topIcon: '🎉',
      headline: '5+ years',
      isRequired: false,
      isMultiple: false,
    },

    relaxTime: {
      title: `How often do you wish you had ${highlightText('more time')} for relaxation or hobbies?`,
      options: [
        '⏰ All the time',
        '🕑 Often',
        '⏳ Sometimes',
        '🕔 Rarely',
        '🕰️ Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    relaxTimeGoal: {
      title: 'What would you do if you had more free time?',
      subTitle: 'Select one:',
      isTilesDisplay: true,
      options: [
        {
          title: 'Get into creative hobbies',
          imageFilePath: 'assets/onboarding/pictures/creative_hobbies.png',
        },
        {
          title: 'Spend more time with loved ones',
          imageFilePath: 'assets/onboarding/pictures/loved_ones.png',
        },
        {
          title: 'Boost personal growth',
          imageFilePath: 'assets/onboarding/pictures/personal_growth.png',
        },
        {
          title: 'Prioritize health and well-being',
          imageFilePath: 'assets/onboarding/pictures/well_being.png',
        },
      ],
      isMultiple: false,
      isRequired: true,
    } as Quiz,

    language: {
      title: `What other language do you know or study besides English?`,
      isTilesDisplay: true,
      options: [
        { 
          title: 'Spanish',
          emoji: '🇪🇸',
        },
        { 
          title: 'French',
          emoji: '🇫🇷',
        },
        { 
          title: 'German',
          emoji: '🇩🇪',
        },
        { 
          title: 'Italian',
          emoji: '🇮🇹',
        },
        { 
          title: 'Portuguese',
          emoji: '🇵🇹',
        },
        { 
          title: 'Chinese',
          emoji: '🇨🇳',
        },
        { 
          title: 'Hindi',
          emoji: '🇮🇳',
        },
        { 
          title: 'None of those',
          emoji: '🤔',
        },
      ],
      isMultiple: false,
      isRequired: true,
    },

    languageInfo: {
      title: highlightText('Languages Open Doors'),
      subTitle: 'Mastering languages can boost your ability to tackle complex problems! Peech supports any language you speak or learn. Check it out:',
      rive: {
        fileName: 'people_group_one',
        animations: [],
        ratio: 1024 / 675,
      },
      isMultiple: false,
      isRequired: false,
    },

    time: {
      title: 'When do you usually read or listen to content?',
      subTitle: 'Select all that apply:',
      options: [
        {
          emoji: '☕️',
          title: 'In the morning',
        }, {
          emoji: '🏃‍♂️',
          title: 'While working out',
        }, {
          emoji: '🚗',
          title: 'On my commute',
        }, {
          emoji: '🛋',
          title: 'While taking a break',
        },
      ],
      isMultiple: true,
      isRequired: true,
    } as Quiz,

    duration: {
      title: 'How many hours per day you could potentially listen to audio content?',
      options: [
        {
          title: '⏰ Less than 1 hour',
        }, {
          title: '⏱️ 1–2 hours',
        }, {
          title: '⌛ 2–3 hours',
        }, {
          title: '🕰️ 3–4 hours',
        }, {
          title: '🕛 More than 4 hours',
        },
      ],
      isMultiple: false,
      isRequired: true,
    },
    durationInfo: {
      title: 'That\'s 4x more than most people!',
      subTitle: 'You are in top 25% among your age.',
      rive: {
        fileName: 'comparison',
        animations: ['Timeline 1'],
        ratio: 1.2,
      },
      isMultiple: false,
      isRequired: false,
    },
    society: {
      title: 'Making Others Proud',
      subTitle: 'How important is it for you to make your parents, relatives, or friends proud of your academic achievements?',
      options: [
        '👪 Very important',
        '👏 Important, but my goals matter more',
        '🤔 Somewhat important',
        '😊 Not very important',
        '🙅 Not important at all',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    societyInfo: {
      title: 'Fun Fact',
      subTitle: 'Studies show that students who focus on both personal and external motivations, like making loved ones proud, are more likely to succeed academically.',
      rive: {
        fileName: 'people_group_two',
        animations: [],
        marginTop: '-30%',
        ratio: 1024 / 759,
      },
      isMultiple: false,
      isRequired: false,
    },
    opportunities: {
      title: highlightText('Knowledge = Money?'),
      subTitle: 'Do you believe your skills and knowledge will lead to higher earnings?',
      options: [
        '💼 Yes, skills = better pay',
        '💸 Probably, not sure how much',
        '🤔 Maybe, but other factors matter more',
        '🙃 No, not really',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    honest: {
      title: `Thanks for being honest!`,
      subTitle: `You are doing great! There are only few steps left`,
      rive: {
        fileName: 'hearts',
        animations: ['Hearts'],
        ratio: 1.75 / 1,
      },
      isMultiple: false,
      isRequired: false,
    },

    result: {
      title: `The quiz is done. Cheers! 👍`,
      subTitle: 'Now it’s time to take the next leap. As a special thank you, we’re offering you an exclusive deal to help you reach your goals even faster.',
      rive: {
        fileName: 'graph',
        animations: ['Timeline 2'],
        ratio: 390 / 277,
      },
      isMultiple: false,
      isRequired: false,
    },


    // format: {
    //   title: 'What text do you want to turn into audio?',
    //   options: [
    //     '📚 Textbooks & Assignments',
    //     '🌐 Web Articles & Blogs',
    //     '📧 Emails & Newsletters',
    //     '📄 Documents & PDFs',
    //     '✍️ My Own Writing, Notes & Memos',
    //     '📖 eBooks',
    //     '📑 Printed Texts',
    //     '💬 Social Media (Facebook, X, Instagram)',
    //     '🤔 Other'
    //   ].map(title => ({ title })),
    //   isMultiple: true,
    //   isRequired: true,
    // },
    // formatInfo: {
    //   title: 'Great choice!',
    //   subTitle: 'We support uploading these types of texts',
    //   topIcon: '🏋️‍♀️',
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // resultInfo: {
    //   title: `${highlightText('87% of Peech users')} have made positive changes in their lives`,
    //   subTitle: 'You\'re already on your way to positive change, too! Let\'s see what you are interested in exactly.',
    //   topIcon: '🤓',
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // languageAudio: {
    //   title: `Peech Supports more than 60 languages!`,
    //   subTitle: `Listen to English demo at least!`,
    //   audioTracks: [languageTracks.en],
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // soundInfo: {
    //   title: 'Check out how the content sounds in our app!',
    //   subTitle: 'The AI voices are amazing and easy to understand',
    //   audioTracks: [audioTracks.business, audioTracks.history],
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // speedInfo: {
    //   title: `How about getting it ${highlightText('a bit faster?')}`,
    //   subTitle: 'Let\'s try this on x1.5 and x2 speed. Using speed contol is true productivity boost and one of favorite features among our users',
    //   audioTracks: [audioTracks.medicine, audioTracks.physics],
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // speedStatsInfo: {
    //   title: `With Peech you can save ${highlightText('at least 2 hours')} on reading this week!`,
    //   topIcon: '⏱',
    //   userReview: {
    //     message: 'The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend.',
    //     name: 'Jerin Barnes',
    //     avatarUrl: 'assets/img/avatars/5.png',
    //   },
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // progressInfo: {
    //   title: `Start reading ${highlightText('more in less time')}`,
    //   subTitle: 'Thanks for taking the quiz 👍',
    //   isMultiple: false,
    //   isRequired: false,
    // },
};

