import { Component } from '@angular/core';
import { CustomizerSettingsService } from 'src/app/services/customizer-settings.service';

@Component({
  selector: 'app-root-landing',
  templateUrl: './root-landing.component.html',
  styleUrl: './root-landing.component.scss'
})
export class RootLandingComponent {
  constructor(
    public themeService: CustomizerSettingsService, //TODO: make sure we support dark theme
  ) {}
}
