import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CustomizerSettingsService {
    private isDarkTheme: boolean;

    constructor() {
        this.isDarkTheme = JSON.parse(localStorage.getItem('isDarkTheme')!);
    }

    toggleTheme() {
        this.isDarkTheme = !this.isDarkTheme;

        localStorage.setItem('isDarkTheme', JSON.stringify(this.isDarkTheme));
    }

    isDark() {
        return this.isDarkTheme;
    }
}
