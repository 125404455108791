<div class="app-name-container"><div class="peech-icon"></div><div class="app-name">Peech</div></div>
<div class="picture"></div>
<div class="title">Enjoy your new reading superpowers</div>
<div class="subtitle">Read or listen to anything you want whenever you want</div>
<div class="rating-container">
    <div class="appstore-icon"></div>
    <div class="users-stats">2M+ Users</div>
    <div class="rating">●●●●◐</div>
</div>
<a class="store-link button" [href]="link" (click)="onLinkClick($event)">Go to Store</a>
