import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CustomizerSettingsService } from '../../../services/customizer-settings.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
    constructor(
        public themeService: CustomizerSettingsService,
        private auth: AuthService
    ) {}

    ngOnInit(): void {
        this.auth.logout().pipe(take(1)).subscribe();
    }
}