import { Component, OnInit } from '@angular/core';
import { AppsFlyerService } from '../../services/apps-flyer.service';

const translations = {
  en: {
      title: 'You are being redirected to App Store',
      subtitle: 'It takes just a few seconds',
  },
  it: {
      title: 'Sei stato reindirizzato all\'App Store',
      subtitle: 'Ci vogliono solo pochi secondi',
  },
  es: {
      title: 'You are being redirected to App Store',
      subtitle: 'It takes just a few seconds',
  },
}

@Component({
  selector: 'app-store-redirect',
  templateUrl: './store-redirect.component.html',
  styleUrl: './store-redirect.component.scss'
})
export class StoreRedirectComponent implements OnInit {
  constructor(private afService: AppsFlyerService) {}

  ngOnInit(): void {
    const urlParams = new URLSearchParams(location.search);

    //setTranslations(urlParams.get('language'));

    document.body.addEventListener('click', () => navigator.clipboard.writeText(`peechapp://${location.search}`)); //just in case

    setTimeout(() => {
        if (fbq) {
            fbq('track', 'ViewContent');
        }

        // if (gtag) {
        //     gtag('event', 'StoreRedirect');
        // }

        setTimeout(() => window.location.href = this.afService.getClickURL(urlParams), 200);
    }, 2300);
  }
}
