<div class="start-container d-flex flex-column align-items-center justify-content-center text-center pb-10">
    <div class="content d-flex flex-column align-items-center justify-content-center light-green-color border-radius p-20" (click)="continueButtonClicked()">
        <h2 class="main-color">Crush Your Study Goals with a Powerful AI Tool</h2>
        <!-- <div class="subtitle">Find out what’s holding you back and learn how to overcome it! Take this quick 2-3 minute quiz!</div> -->

        <div class="cite bg-white border-radius p-25 d-flex flex-column justify-content-center mt-15">
            <div class="d-flex content flex-grow-1">
                <div class="d-flex">
                    <div class="quotas-image image mt-10"></div>
                </div>
                <div class="flex-grow-1 d-flex flex-column justify-content-space-between">
                    <p class="lead">Peech is a text-to-speech app that actually sounds good—an essential tool for students.</p>
                </div>
            </div>
            <div class="mt-15 d-flex justify-content-center">
                <div class="media-logo image"></div>
            </div>
        </div>

        <div class="subtitle mt-15">mentioned in</div>
        <div class="media-logos image mt-5"></div>
    </div>
    <div class="sticky-container p-15 mt-5">
        <button mat-flat-button class="tagus pulsing" (click)="continueButtonClicked()">Get Started</button>
    </div>
    <div class="info gray-color">Florinis 7, Greg Tower, 2nd Floor, 1065, Nicosia, Cyprus</div>
</div>
