import { Component, HostListener, OnInit } from '@angular/core';
import { AppsFlyerService } from '../../services/apps-flyer.service';

const translations = {
  en: {
      link: 'Go to App Store',
      title: 'Enjoy your new reading superpowers',
      subtitle: 'Read or listen to anything you want whenever you want',
      stats: '500K+ Users',
  },
  it: {
      link: "Vai all'App Store",
      title: 'Goditi i tuoi  nuovi superpoteri di lettura',
      subtitle: 'Leggi o ascolta tutto quello che vuoi e quando vuoi',
      stats: '500K+ Utenti',
  },
  es: {
      link: 'Descargar en la App Store',
      title: 'Enjoy your new reading superpowers',
      subtitle: 'Read or listen to anything you want whenever you want',
      stats: '500K+ Usuarios',
  },
}

@Component({
  selector: 'app-store-button',
  templateUrl: './store-button.component.html',
  styleUrl: './store-button.component.scss'
})
export class StoreButtonComponent implements OnInit {
  link: string = 'https://apps.apple.com/us/app/peech-text-reader-for-articles/id1429704005'; //default one

  constructor(private afService: AppsFlyerService) {}

  ngOnInit(): void {
    const urlParams = new URLSearchParams(location.search);

    //urlParams.get('language')
    this.link = this.afService.getClickURL(urlParams);
  }

  @HostListener('body:click')
  onBodyClick(): void {
    this.goToStore();
  }

  onLinkClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.goToStore();
  }

  private goToStore(): void {
    setTimeout(() => window.location.href = this.link, 200);

    if (fbq) {
        fbq('track', 'ViewContent');
    }
    // if (gtag) {
    //     gtag('event', 'StoreRedirect');
    // }
    
    navigator.clipboard.writeText(`peechapp://${location.search}`);
  }
}
