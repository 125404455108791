import * as amplitude from '@amplitude/analytics-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeWhile, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StatsIGService } from './services/stats-ig.service';

@Component({
  selector: 'app-onboarding-root',
  templateUrl: './onboarding-root.component.html',
  styleUrl: './onboarding-root.component.scss'
})
export class OnboardingRootComponent implements OnInit, OnDestroy {
  isLogoShown: boolean = true;
  isActive: boolean = true;

  constructor(private router: Router, private abService: StatsIGService) {}

  ngOnInit(): void {
    this.isLogoShown = !this.router.url.includes('checkout');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(() => {
          this.isLogoShown = !this.router.url.includes('checkout')
      }),
      takeWhile(() => this.isActive),
    ).subscribe();

    this.startAnalytics();
  }

  startAnalytics() {
    amplitude.init(environment.amplitudeKey, {
      defaultTracking: true,
      appVersion: environment.appVersion,
      autocapture: {
        pageViews: false,
      }
    });

    const experiment = this.abService.getExperiment('order_of_goal_screen_in_the_flow');  //is_goal_screen_first
    const isGoalScreenFirst = experiment.get('is_goal_screen_first', false);

    this.abService.setConfigProperty({
      isGoalScreenFirst: isGoalScreenFirst,
    });

    amplitude.track({
      event_type: 'web_onboarding_started',
      user_properties: {
        web_onboarding_version: environment.appVersion,
        [`web_statsig_goal_flow_order`]: isGoalScreenFirst ? 'first' : 'tenth',
      }
    });

    //this.abService.logEvent('test_init');
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }
}
