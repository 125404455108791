import { track } from '@amplitude/analytics-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';

//the URL: 
//?payment_intent=pi_3OjqwRGzd0JjFWBK0Vc4TtJR&payment_intent_client_secret=pi_3OjqwRGzd0JjFWBK0Vc4TtJR_secret_AurL0iJ4KWctSXfnE0E3cpfWc&redirect_status=succeeded

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrl: './payment-result.component.scss'
})
export class PaymentResultComponent implements OnInit {
  isSuccess = true;

  constructor(private route: ActivatedRoute, private pixel: EventsService) {}

  ngOnInit(): void {
    this.isSuccess = this.route.snapshot.queryParamMap.get('redirect_status') !== 'failed';

    if (this.isSuccess) {
      track('web_subscribe_success');
      this.pixel.track('subscribe');
    } else {
      track('web_subscribe_failure');
    }
  }
}
