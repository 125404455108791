import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, catchError, filter, map, take, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { CustomizerSettingsService } from '../../../services/customizer-settings.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    hide = true;

    emailControl = new FormControl('', [Validators.email, Validators.required]);
    passwordControl = new FormControl('', [Validators.minLength(5), Validators.required])

    constructor(
        public themeService: CustomizerSettingsService,
        private authService: AuthService,
        private router: Router,
        private eventsService: EventsService
    ) {}

    ngOnInit(): void {
        //this.eventsService.track('page_view');
    }

    signInClicked() {
        if (!this.emailControl.valid || !this.passwordControl.valid) {
            //TODO: show errors
            return;
        }

        this.authService.loginWithEmail(this.emailControl.value!, this.passwordControl.value!)
            .pipe(
                take(1),
                filter(isSuccess => isSuccess),
                tap(() => this.router.navigate(['account'])),
                catchError(error => {
                    console.log(error);

                    this.emailControl.reset();
                    this.passwordControl.reset();

                    return EMPTY;
                }),
            )
            .subscribe();
    }

    googleClicked() {
        this.authService.login('google')
            .pipe(
                take(1),
                map(user => !!user),
                filter(isSuccess => isSuccess),
                tap(() => this.router.navigate(['account'])),
            )
            .subscribe();
    }
}
