import { Component } from '@angular/core';
import { CustomizerSettingsService } from '../../../services/customizer-settings.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

    constructor(
        public themeService: CustomizerSettingsService
    ) {}
}