import { Component } from '@angular/core';
import { CustomizerSettingsService } from '../../../services/customizer-settings.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

    hide = true;

    constructor(
        public themeService: CustomizerSettingsService
    ) {}
}