import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { AudioTrack } from 'src/app/shared/audio-track/audio-track.component';
import { UserReview } from 'src/app/shared/user-review/user-review.component';
import { audioTracks, highlightText, interests, languageTracks, Quiz, quizSteps, RiveAnimationConfig, studySubjects } from './quiz';
import { StatsIGService } from './stats-ig.service';

enum ConsumptionTime {
  morning,
  commute,
  workingOut,
  break,
}

enum Gender {
  male,
  female,
  other,
}

type Interest = typeof interests[number] | typeof studySubjects[number];

export type QuizStepKey = keyof typeof quizSteps;

const interestTrackMap: { [key in Interest]: AudioTrack } = {
  '🏛 History': audioTracks.history,
  '💼 Business Management': audioTracks.business,
  '⚕️ Clinical Medicine': audioTracks.medicine,
  '🔬 Physics': audioTracks.physics,
  '💔 Dark Romance': audioTracks.darkRomance,
  '🌍 Environment & Sustainability': audioTracks.environment,
  '📖 Fan Fiction': audioTracks.fanfiction,
  '🛡 Fantasy': audioTracks.fantasy,
  '📊 Finance & Investing': audioTracks.finance,
  '🏋️ Fitness & Health': audioTracks.fitness,
  '📣 Marketing': audioTracks.marketing,
  '🧠 Mental Health': audioTracks.mentalHealth,
  '📰 News & Current Events': audioTracks.news,
  '👪 Parenting & Family': audioTracks.parenting,
  '🚀 Personal Growth & Motivation': audioTracks.personalGrowth,
  '📜 Religion & Spirituality': audioTracks.religion,
  '❤️ Romance': audioTracks.romance,
  '🔍 Thriller & Mystery': audioTracks.thrillerMystery,
  '🏦 Wealth Building': audioTracks.wealthBuilding,
  '💆 Well-Being': audioTracks.wellBeing,
  '🧬 Science': audioTracks.science,
  '📐 Mathematics': audioTracks.math,
  '📖 Literature': audioTracks.literature,
  '💻 Computer Science': audioTracks.cs,
  '⚖️ Law': audioTracks.law,
  '🧮 Economics': audioTracks.economics,
  '🌍 Political Science': audioTracks.politics,
  '🧪 Biology': audioTracks.biology,
  '🎨 Arts': audioTracks.arts,
};

const initialQuizStepOrder: QuizStepKey[] = [
  'gender',
  'age',
  'amountInfo',
  'level',
  'overwhelming',
  'overwhelmingInfo',
  'balance',
  'distraction',
  'retention',
  'retentionInfo',
  'goal',
  'goalInfo',
  'anxiety',

  'procrastination',

  'studySubjects', 
  'studySubjectsInfo',
  'interests',
  'interestsInfo',
  'afterStudyGoal',

  'technology',
  'technologyInfo',

  'audio',
  'audioInfo',

  'eyes',
  'eyesBreak',

  'screenTime',
  'screenTimeSocialProof',
  'screenTimeQuestion',
  'screenTimeResult',
  'screenTimeImprovement', 

  'relaxTime',
  'relaxTimeGoal',
  'language',
  'languageInfo',

  'time',
  'duration',
  'durationInfo',
  'society',
  'societyInfo',
  'opportunities', 
  'honest', //here we go to customization step and //after this step we have login
  'result',
];

const goalFirstQuizStepOrder: QuizStepKey[] = [
  'goal',
    //'goalInfo',
  'gender',
  'age',
  'amountInfo',
  'level',
  'overwhelming',
  'overwhelmingInfo',
  'balance',
  'distraction',
  'retention',
  'retentionInfo',
  'anxiety',

  'procrastination',

  'studySubjects', 
  'studySubjectsInfo',
  'interests',
  'interestsInfo',
  'afterStudyGoal',

  'technology',
  'technologyInfo',

  'audio',
  'audioInfo',

  'eyes',
  'eyesBreak',

  'screenTime',
  'screenTimeSocialProof',
  'screenTimeQuestion',
  'screenTimeResult',
  'screenTimeImprovement', 

  'relaxTime',
  'relaxTimeGoal',
  'language',
  'languageInfo',

  'time',
  'duration',
  'durationInfo',
  'society',
  'societyInfo',
  'opportunities', 
  'honest', //here we go to customization step and //after this step we have login
  'result',
];

type QuizSteps = {
  [K in QuizStepKey]: Quiz;
};

@Injectable({
  providedIn: 'root'
})
export class OnboardingFlowService {
  private steps: QuizSteps = quizSteps;

  private times: ConsumptionTime[] = [];

  private gender: Gender;
  private age: string;
  private interests: Interest[] = [];
  private subjects: Interest[] = [];
  private lastPlayedTrack: AudioTrack | null = null;

  private name: string | undefined;
  private stripeCustomerId: string | undefined;

  private topTitle = 'Unlock Your Potential';
  private graphAnimationName = 'Timeline 2';

  private paywallLevelTitle: string;
  private paywallImprovementTitle: string;

  private lostTimeYear = '76 days';
  private lostTimeLife = '12 years';
  private recoveredTimeLife = '5 years+';

  private secondLanguage: keyof typeof languageTracks = 'es';

  private secondGoal: string;

  //this is to device whether to show some of the next screens:
  private eyesStrainFeeling: string;
  private screenTimeSatisfaction: string;
  private overwhelmedFeeling: string;

  private quizStepOrder: QuizStepKey[];

  constructor(private router: Router, private pixel: EventsService, private abTestService: StatsIGService) {
    if (this.abTestService.getTestingProps().isGoalScreenFirst) {
      this.quizStepOrder = [...goalFirstQuizStepOrder];
    } else {
      this.quizStepOrder = [...initialQuizStepOrder];
    }
  }

  goToStart() {
    this.router.navigate(['onboarding', 'start']);
  }

  handleFinishedMagazinesProofScreen() {
    amplitude.track('web_magazines_proof_viewed');
    this.router.navigate(['onboarding', 'quiz', 1]);
  }

  handleFinishedCustomization() {
    //const indexToGo = this.quizStepOrder.indexOf('result');

    amplitude.track('web_customization_screen_viewed');

    //this.router.navigate(['onboarding', 'quiz', indexToGo]);
    this.router.navigate(['onboarding', 'login']);
  }

  handleFinishedLogin(stripeCustomerId: string) {
    const indexToGo = this.quizStepOrder.indexOf('result');
    
    this.stripeCustomerId = stripeCustomerId;
    //event of login is fired in auth service
    
    this.router.navigate(['onboarding', 'quiz', indexToGo]);
  }

  handleFinishedName(name: string) {
    const indexToGo = this.quizStepOrder.indexOf('society');

    this.name = name;

    amplitude.track('web_name_entered', { name }); //TODO: maybe set name to user prop instead 
    
    this.router.navigate(['onboarding', 'quiz', indexToGo]);
  }

  handleFinishedStep(index: number, options: string[]) {
     const key = this.quizStepOrder[index];

     this.trackResponse(key, options);
     this.saveParameters(key, options);
     
     if (index + 1 >= this.quizStepOrder.length) {
      this.router.navigate(['onboarding', 'checkout']);
     } else if (index === 0) {
      this.router.navigate(['onboarding', 'proof']);
     } else if (key === 'honest') {
      this.router.navigate(['onboarding', 'ready']);
     //} else if (key === 'screenTimeImprovement') {
     // this.router.navigate(['onboarding', 'login']);
     } else {
      this.router.navigate(['onboarding', 'quiz', index + 1]);
     }
  }

  getName(): string | undefined {
    return this.name;
  }

  getStripeCustomerId(): string | undefined {
    return this.stripeCustomerId;
  }

  private customizeStepData(key: QuizStepKey): Quiz | undefined {
    switch (key) {
      case 'amountInfo':
        const count = this.age === '👩‍💼 21-34' ? '4 Million' : (this.age === '👨‍💻 35-44' ? '3 Million' : '5 Million');
        let animationName = 'both';
        let title = 'Students';

        if (this.gender === Gender.male) {
          title = 'Male Students';
          animationName = 'male';
        } else if (this.gender === Gender.female) {
          title = 'Female Students';
          animationName = 'female';
        }

        return {
          ...this.steps.amountInfo,
          rive: {
            ...this.steps.amountInfo.rive as RiveAnimationConfig,
            animations: ['appear', animationName], //["idle","female","both","male","appear"]
          },
          title: `Join ${highlightText(`Over ${count} ${title}`)} Your Age Worldwide`,
        };
      
      case 'studySubjectsInfo':
        return {
          ...this.steps.studySubjectsInfo,
          audioTracks: [this.getUserAudioTracks().subject]
        }

      case 'screenTimeResult':
        return {
          ...this.steps.screenTimeResult,
          title: `The bad news: You're on track to spend ${highlightText(this.lostTimeYear, true)} on your phone this year. Over your lifetime, this adds up to:`,
          headline: this.lostTimeLife,
          subTitle: `Think about all the valuable time you could reclaim for things that truly matter`,
        }

      case 'screenTimeImprovement':
        return {
          ...this.steps.screenTimeImprovement,
          headline: this.recoveredTimeLife,
        };

      case 'result':
        return {
          ...this.steps.result,
          rive: {
            ...this.steps.result.rive as RiveAnimationConfig,
            animations: [this.graphAnimationName],
          },
        };

      case 'languageInfo':
        return {
          ...this.steps.languageInfo,
          audioTracks: [this.getUserAudioTracks().language]
        };

      default:
        return;
    }
  }

  private shouldSkipStep(key: QuizStepKey): Boolean {
    switch(key) {
      case 'eyesBreak':
        return ['Rarely','Never'].includes(this.eyesStrainFeeling);
      case 'screenTimeSocialProof':
        return ['Very satisfied', 'Somewhat satisfied'].includes(this.screenTimeSatisfaction);
      case 'overwhelmingInfo':
        return ['Rarely','Never'].includes(this.overwhelmedFeeling);
      default:
        return false;
    }
  }

  getQuizData(index: number): Quiz {
    const key = this.quizStepOrder[index];

    if(this.shouldSkipStep(key) && index < this.getQuizLength() - 1) {
      this.router.navigate(['onboarding', 'quiz', index + 1]);
    }

    return this.customizeStepData(key) || this.steps[key];
  }

  getQuizLength(): number {
    return this.quizStepOrder.length;
  }

  rememberLastPlayedTrack(audio: AudioTrack): void {
    amplitude.track('web_audio_demo_played', { title: audio.title });

    this.lastPlayedTrack = audio; // not used atm
  }

  getTopTitle(): string {
    return this.topTitle;
  }

  getSecondaryGoal(): string {
    return this.secondGoal || 'Build a happy family';
  }

  getPaywallComparisonLabels(): { level: string, graph: string } {
    return {
      level: this.paywallLevelTitle || 'Stress and Anxiety',
      graph: this.paywallImprovementTitle || 'Comprehension',
    }
  }

  getPaywallMainImageClassName(): string {
    if (this.gender === Gender.male) {
      return `comparison_male`;
    }

    return `comparison_female`;
  }

  getUserAudioTracks(): { subject: AudioTrack, leisure: AudioTrack, language: AudioTrack } {
    const language = languageTracks[this.secondLanguage] || languageTracks.es;
    const leisure = interestTrackMap[this.interests?.[0]] ?? interestTrackMap['🔍 Thriller & Mystery'];
    const subject = interestTrackMap[this.subjects?.[0]] ?? interestTrackMap['💻 Computer Science'];

    return { language, leisure, subject };
  }

  private saveParameters(currentStepKey: QuizStepKey, selectedOptions: string[]) {
    switch (currentStepKey) {
      case 'gender':
        if (selectedOptions[0].includes('Male')) {
          this.gender = Gender.male;
        } else if (selectedOptions[0].includes('Female')) {
          this.gender = Gender.female;
        } else {
          this.gender = Gender.other;
        }
        break;
      case 'age':
        this.age = selectedOptions[0];
        break;
      case 'goal':
        // '🎯 Crush my exams',
        // '🕒 Free up time for yourself',
        // '😓 Reduce academic stress and anxiety',
        // '🧠 Boost my memory and comprehension',
        // '🚀 Prepare for a successful career',
        switch (selectedOptions[0]) {
          case 'Crush my exams':
            this.topTitle = 'Ace your exams';
            this.graphAnimationName = 'Timeline 1';

            break;
          case 'Free up time for yourself':
            this.topTitle = 'Master study-life balance';
            this.graphAnimationName = 'Timeline 2';
            this.paywallImprovementTitle = 'Free Time';

            break;
          case 'Reduce academic stress and anxiety':
            this.topTitle = 'Study stress-free';
            this.graphAnimationName = 'Timeline 3';
            this.paywallLevelTitle = 'Stress and Anxiety';

            break;
          case 'Boost my memory and comprehension':
            this.topTitle = 'Supercharge your memory';
            this.graphAnimationName = 'Timeline 4';
            this.paywallImprovementTitle = 'Comprehension';

            break;
          case 'Prepare for a successful career':
            this.topTitle = 'Launch your career success';
            this.graphAnimationName = 'Timeline 5';

            break;
          default:
            console.log('Error: Unknown goal detected!')
            break;
        }
        break;
      case 'afterStudyGoal':
        this.secondGoal = this.removeEmojis(selectedOptions[0]);
        break;
      case 'eyes':
        this.eyesStrainFeeling = this.removeEmojis(selectedOptions[0]);
        break;
      case 'overwhelming':
        this.overwhelmedFeeling = this.removeEmojis(selectedOptions[0]);
        break;
      case 'screenTime':
        this.screenTimeSatisfaction = this.removeEmojis(selectedOptions[0]);
        break;
      case 'language':
        switch (selectedOptions[0]) {
          case 'Spanish':
            this.secondLanguage = 'es';
            break;
          case 'French':
            this.secondLanguage = 'fr';
            break;
          case 'German':
            this.secondLanguage = 'de';
            break;
          case 'Italian':
            this.secondLanguage = 'it';
            break;
          case 'Portuguese':
            this.secondLanguage = 'pt';
            break;
          case 'Chinese':
            this.secondLanguage = 'zh';
            break;
          case 'Hindi':
            this.secondLanguage = 'hi';
            break;
          default:
            this.secondLanguage = 'es';
            break;
        }
        break;
      case 'screenTimeQuestion':
        const yearsMultiplicator = 62; //left to live
        const saveCoef = 0.4;
        let hoursMultiplicator = 4;

        switch (selectedOptions[0]) {
          case '1-2 hours':
            hoursMultiplicator = 1.5;
            break;
          case '2-3 hours':
            hoursMultiplicator = 2.5;
            break;
          case '3-5 hours':
            hoursMultiplicator = 4;
            break;
          case '5-8 hours':
            hoursMultiplicator = 6.5;
            break;
          case '8-12 hours':
            hoursMultiplicator = 10;
            break;
          case '12+ hours':
            hoursMultiplicator = 13;
            break;
        }

        const daysCount = Math.ceil(hoursMultiplicator * 365 / 24);
        const yearsCount =  Math.ceil(daysCount * yearsMultiplicator / 365);
        const recoveredYears = Math.ceil(saveCoef * yearsCount);

        this.lostTimeYear = daysCount > 1 ? `${daysCount} days` : '1 day';
        this.lostTimeLife = yearsCount > 1 ? `${yearsCount} years` : '1 year';
        this.recoveredTimeLife = recoveredYears > 1 ? `${recoveredYears} years+` : '1 year+';
        break;
      case 'interests':
        this.interests = selectedOptions as Interest[];
        break;
      case 'studySubjects':
        this.subjects = selectedOptions as Interest[];
        break;
      case 'anxiety':
        if (['😰 Yes, it’s significant', '😟 Yes, to some extent'].includes(selectedOptions[0])) {
          this.paywallLevelTitle = 'Stress and Anxiety';
        }
        break;
      case 'procrastination':
        if (['🐌 Always', '🐢 Frequently'].includes(selectedOptions[0])) {
          this.paywallLevelTitle = 'Procrastination';
        }
        break;
      case 'balance':
        if (['⚖️ Yes, it’s a constant struggle', '🏃 Yes, quite often'].includes(selectedOptions[0])) {
          this.paywallImprovementTitle = 'Free Time';
        }
        break;
      case 'retention':
        if (['🤔 Yes, all the time', '😕 Yes, often'].includes(selectedOptions[0])) {
          this.paywallImprovementTitle = 'Learning Retention';
        }
        break;
    }
  }

  private getUserReview(): UserReview {
    const review = {
      name: 'Jerin Barnes',
      avatarUrl: 'assets/img/avatars/5.png',
    }

    return {
      ...review,
      message: 'Peech has given me the edge in my academic journey, helping me absorb concepts on the go.',
    };
  
    // switch (this.userCohort) {
    //   case Cohort.disability:
    //     return {
    //       ...review,
    //       message: 'As someone with dyslexia, Peech has been a lifesaver. It makes reading so much easier and more accessible. I can finally enjoy books and articles without struggling.'
    //     }
    //   case Cohort.education:
        
    //   case Cohort.fiction:
    //     return {
    //       ...review,
    //       message: 'Peech has become my go-to app for unwinding with a good story. I can finally enjoy more books during my downtime, and the experience is just as immersive as reading.',
    //     };
    //   case Cohort.productivity:
    //     return {
    //       ...review,
    //       message: 'Peech is my secret weapon for staying productive while running my business. It lets me absorb key information without slowing down.',
    //     };
    // }
  }

  private removeEmojis(string: string): string {
    return string.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}]/gu, '').trim();
  }

  private trackResponse(currentStepKey: QuizStepKey, options: string[]): void {
    amplitude.track('web_quiz_step_finished', { key: currentStepKey, options });
  }
}
