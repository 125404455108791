<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">Get Started</h2>
                        <img src="assets/img/logo-icon.png" class="logo" alt="logo-icon">
                    </div>
                    <!-- <p>Don’t have an account? <a routerLink="/authentication/register" class="main-color fw-medium">Register now!</a></p> -->
                </div>
                <form>
                    <!-- <div class="login-with-socials d-md-flex align-items-center justify-content-space-between">
                        <button mat-flat-button class="gray fw-semibold d-block me-5" (click)="googleClicked()"><img src="assets/img/icon/google.png" alt="google"> Login with Google</button>
                        <button mat-flat-button class="tagus fw-semibold d-block ms-5"><img src="assets/img/icon/facebook.png" alt="facebook"> Login with Facebook</button>
                    </div>
                    <div class="or text-center position-relative">
                        <span class="fw-semibold fs-16">or</span>
                    </div> -->
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input matInput [formControl]="emailControl">
                            </mat-form-field>
                        </div>
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" [formControl]="passwordControl">
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="d-flex info align-items-center justify-content-space-between"> -->
                        <!-- <mat-checkbox class="gray-color">Remember me</mat-checkbox> -->
                        <!-- <a routerLink="/authentication/forgot-password" class="d-inline-block main-color fw-medium">Forgot your password?</a> -->
                    <!-- </div> -->
                    <button mat-flat-button class="tagus d-block fw-semibold" (click)="signInClicked()">Log In</button>
                </form>
            </div>
        </div>
    </div>
</div>