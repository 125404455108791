<div class="payment-container p-15 d-flex flex-column align-items-center">
    <div class="dismiss-hint"></div>
    <h4 class="text-center"><span class="main-color">91% of users</span> stay with us after initial 30-day subscription</h4>

    <div class="d-flex w-100 justify-content-space-between">
        <div class="fw-semibold">Total:</div>
        <div>{{totalLabel}}</div>
    </div>

    <div *ngIf="isLoading" class="w-100 d-flex p-30 justify-content-center">
        <mat-spinner mode="indeterminate" diameter='50'></mat-spinner>
    </div>

    <form #stripeForm class="w-100 d-flex flex-column mt-10" [class.hidden]="isLoading">
        <h5 class="text-center">Enter payment method</h5>
        <div #stripeEmail class="mb-20" *ngIf="!email">
            <!-- <span *ngIf="email" class="muted-color">Email: {{email}}</span> -->
        </div>
        <div #stripeElement></div>
        <button mat-flat-button class="fw-semibold tagus w-100 mt-25">{{purchaseButtonTitle}}</button>
        <div class="text-center fs-14 mt-15 mb-10" *ngIf="isExpressMethodsAvailable">Or Use Quick Option:</div>
        <div class="mt-5" #expressCheckout *ngIf="isExpressMethodsAvailable"></div>
        <div *ngIf="isDisclaimerShown" class="text-hint mt-20">We’ve automatically applied discount to your first subscription price. You will be charged {{product.priceLabel}}. Please note that your subscription will be automatically renewed at full price of {{product.fullPriceLabel}} at the end of chosen subscription term. Terms and Conditions apply. If you want to manage your subscription, you may do so via your personal account.</div>
    </form>
      <!-- <p class="text-center">or</p>
      <div class="paypal-container" #paypal></div> -->
</div>