import { Component } from '@angular/core';
import { filter, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CustomizerSettingsService } from '../../../services/customizer-settings.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    name = 'Your Name'
    avatarUrl = 'assets/img/logo-icon.png';
    isLoggedIn = false;
    isLogoAvatar: boolean = false; //we hide our logo because it is the same to default avatar which makes it look ugly
    
    constructor(
        public themeService: CustomizerSettingsService,
        private authService: AuthService,
    ) {
        this.authService.user$
            .pipe(
                tap(user => this.isLoggedIn = !!user),
                filter(user => !!user),
                tap(user => {
                    if (user?.photoURL) {
                        this.avatarUrl = user.photoURL;
                    } else {
                        this.isLogoAvatar = true;
                    }

                    this.name = user?.displayName || user?.email || this.name;
                }),
            )
            .subscribe();
    }
}