import * as amplitude from '@amplitude/analytics-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { concat, defer, finalize, interval, map, takeWhile, tap } from 'rxjs';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-preparation',
  templateUrl: './preparation.component.html',
  styleUrls: ['./preparation.component.scss']
})
export class PreparationComponent implements OnInit, OnDestroy {
  value = 0;

  private isActive = true;

  private duration = Math.random() * 5000 + 6000;
  private intervalDuration = 50;

  constructor(private flowService: OnboardingFlowService, private dialog: MatDialog) {}

  ngOnInit(): void {
    const steps = [
      {
        toValue: 39,
        question: { title: 'Do you like to learn by listening?', message: 'To move forward, specify' },
      },
      {
        toValue: 74,
        question: { title: 'Are you inclined to finish what you start?', message: 'To move forward, specify' },
      },
      { toValue: 100, question: null },
    ];

    let previousValue = 0;

    const stepObservables = steps.map((step) => {
      const progressDifference = step.toValue - previousValue;
      const stepDuration = (progressDifference / 100) * this.duration;

      const progress$ = this.createProgressObservable(
        previousValue,
        step.toValue,
        stepDuration
      );

      previousValue = step.toValue;

      if (step.question) {
        return concat(
          progress$,
          defer(() => this.askQuestion(step.question.title, step.question.message))
        );
      } else {
        return progress$;
      }
    });

    concat(...stepObservables)
      .pipe(
        takeWhile(() => this.isActive),
        finalize(() => {
          if (this.isActive) {
            this.value = 100;
            this.flowService.handleFinishedCustomization();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  private createProgressObservable(
    startValue: number,
    endValue: number,
    duration: number
  ) {
    const progressDifference = endValue - startValue;

    return interval(this.intervalDuration).pipe(
      takeWhile(() => this.isActive),
      map((elapsedIntervals) => {
        const elapsed = (elapsedIntervals + 1) * this.intervalDuration;
        const t = Math.min(elapsed / duration, 1);
        const easedProgress = this.cubicEaseOut(t);
        const currentValue = Math.min(
          Math.round(startValue + easedProgress * progressDifference),
          endValue
        );
        return currentValue;
      }),
      tap((currentValue) => {
        this.value = currentValue;
      }),
      takeWhile((currentValue) => currentValue < endValue)
    );
  }

  private askQuestion(title: string, message: string) {
    return this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      disableClose: true,
      data: {
        title,
        message,
        okButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
        isConfirmation: true,
      },
    })
    .afterClosed()
    .pipe(
      tap(isYes => amplitude.track('web_customization_question_responded', {
        positive: isYes,
        question: title,
      })),
    )
  }

  private cubicEaseOut(t: number): number {
    return --t * t * t + 1;
  }
}
