<div
    class="footer-area bg-white border-top-radius box-shadow"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="row flex-column">
        <div class="col-12 d-flex align-items-center">
            <div class="logo"></div>
            <span class="name ms-10">Peech</span>
        </div>
        <p class="mt-15">Convert anything into beautiful audio!</p>
    </div>
    <div class="row mt-20">
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-20 d-flex flex-column">
            <strong>Product</strong>
            <a class="mt-25" href="https://apps.apple.com/app/apple-store/id1429704005?pt=119230880&ct=web-onboarding-footer&mt=8">iOS App</a>
            <a class="mt-15" href="https://www.getpeech.com/chrome-extension">Chrome Extension</a>
            <a class="mt-15" routerLink="/authentication/login">Web Login</a>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-20 d-flex flex-column">
            <strong>Support</strong>
            <div class="mt-25">For questions or feedback please email <a class="main-color" href="mailto:support@getpeech.com">support&#64;getpeech.com</a></div>
            <a class="mt-15" href="https://www.getpeech.com/privacy">Privacy</a>
            <a class="mt-15" href="https://www.getpeech.com/terms">Terms of Service</a>
        </div>
    </div>
    <p class="text-center mt-30 mb-10">Peech is Proudly Owned by Dopefin Limited © 2024</p>
</div>