<div class="d-flex justify-content-center gap-10">
    <div class="d-flex flex-column align-items-center text-center gap-5">
        <span class="apple-logo ri-apple-fill"></span>
        <div class="d-flex align-items-center gap-5">
            <div class="branch left-branch"></div>
            <span class="achievement-title">#1 in the AppStore</span>
            <div class="branch right-branch"></div>
        </div>
        <span class="achievement-subtitle">For Magazines and Newspapers</span>
    </div>

    <div class="d-flex flex-column align-items-center text-center gap-5">
        <span class="review-stars">●●●●●</span>

        <div class="d-flex align-items-center gap-5">
            <div class="branch left-branch"></div>
            <span class="achievement-title">15,000+ 5 Star Reviews</span>
            <div class="branch right-branch"></div>
        </div>   
        <span class="achievement-subtitle">In 64 Countries</span>
    </div>
</div>