import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/authentication/login/login.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { PeechLoginComponent } from './components/authentication/peech-login/peech-login.component';
import { InternalErrorComponent } from './components/common/internal-error/internal-error.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SuccessComponent } from './components/success/success.component';
import { authGuard } from './guards/auth.guard';
import { EducationLandingComponent } from './components/landings/education/education-landing.component';
import { ExistingUserDiscountPaywallComponent } from './components/landings/existing-user-discount-paywall/existing-user-discount-paywall.component';
import { RootLandingComponent } from './components/landings/root-landing/root-landing.component';
import { StoreButtonComponent } from './w2a/components/store-button/store-button.component';
import { StoreRedirectComponent } from './w2a/components/store-redirect/store-redirect.component';

const routes: Routes = [
    { path: '', component: RootLandingComponent },
    { path: 'education', component: EducationLandingComponent },
    { path: 'user-discount', component: ExistingUserDiscountPaywallComponent },
    // {path: 'checkout', component: CheckoutComponent},
    { path: 'result', component: PaymentResultComponent },
    { path: 'account', component: ProfileComponent, canActivate: [authGuard] },
    { path: 'error-500', component: InternalErrorComponent },
    { path: 'authentication/login', component: LoginComponent },
    { path: 'authentication/logout', component: LogoutComponent },
    // {path: 'authentication/forgot-password', component: ForgotPasswordComponent},
    // {path: 'authentication/reset-password', component: ResetPasswordComponent},
    // {path: 'authentication/register', component: RegisterComponent},
    // {path: 'authentication/confirm-mail', component: ConfirmMailComponent},
    { path: 'w2a/go-to-store', component: StoreButtonComponent },
    { path: 'w2a/redirect', component: StoreRedirectComponent },

    { path: 'extension-login', component: PeechLoginComponent }, 
    { path: 'login', component: PeechLoginComponent }, 
    { path: 'success', component: SuccessComponent },

    //it can be lazy loaded if needed
    { path: 'onboarding', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule) },

    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}