<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="logout-box text-center bg-white border-radius ms-auto me-auto">
                <div class="logo">
                    <img src="assets/img/logo.png" alt="logo" *ngIf="!themeService.isDark()">
                    <img src="assets/img/white-logo.png" alt="logo" *ngIf="themeService.isDark()">
                </div>
                <img src="assets/img/coffee.png" alt="coffee">
                <h5 class="fw-semibold mb-12">You are Logged Out</h5>
                <p>Thank you for using <span class="fw-semibold">Peech</span></p>
                <a mat-flat-button class="tagus d-block fw-semibold" routerLink="/authentication/login">Sign In</a>
                <a mat-flat-button class="mt-15 main-color" routerLink="/">Go to Main Page</a>
            </div>
        </div>
    </div>
</div>