<div class="paywall-container">
  <div class="header d-flex align-items-center justify-content-space-between">
    <div class="header-text d-flex flex-column justify-content-space-between h-100">
      <div class="header-title">{{discountTitle}} discount</div>
      <div>is reserved for:</div>
    </div>
    <div class="d-flex align-items-center gap-15">
      <div class="header-timer fw-semibold">{{ timer }}</div>
      <button mat-flat-button class="tagus" (click)="topButtonClicked()">{{purchaseButtonTitle}}</button>
    </div>
  </div>

  <div class="d-flex mt-20 justify-content-center w-100">
    <div class="d-flex flex-column justify-content-center align-items-center p-15 comparison light-green-color border-radius">
      <div class="top-title d-flex w-100 mb-10 bg-white border-radius p-15">
        <div class="top-label">Now</div>
        <div class="top-label main-color">With Peech</div>
      </div>
      <div class="top-image-square-container">
        <div class="image" [class]="topImageClassName"></div>
      </div>
      <div class="bottom-info d-flex w-100 mt-10 bg-white border-radius p-15 justify-content-space-between">
        <div class="d-flex flex-column">
          <div class="bottom-title">{{topLevelTitle}}</div>
          <div class="bottom-subtitle">High level</div>
          <div class="bottom-divider mt-5 mb-5"></div>
          <div class="bottom-title">{{topImprovementTitle}}</div>
          <div class="bottom-subtitle">Weak</div>
          <div class="progress-bar">
            <div class="progress-fill low" style="width: 18%;"></div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="bottom-title">{{topLevelTitle}}</div>
          <div class="bottom-subtitle">Low level</div>
          <div class="bottom-divider mt-5 mb-5"></div>
          <div class="bottom-title">{{topImprovementTitle}}</div>
          <div class="bottom-subtitle">Strong</div>
          <div class="progress-bar">
            <div class="progress-fill high" style="width: 84%;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-info d-flex w-100 mt-30 gap-5 mb-20">
    <div class="d-flex flex-column light-green-color border-radius p-10">
      <div class="bottom-title text-center">📚 Main Goal</div>
      <div class="bottom-subtitle text-center" [innerHTML]="topTitle"></div>
    </div>
    <div class="d-flex flex-column light-green-color border-radius p-10">
      <div class="bottom-title text-center">🌟 Life Goal</div>
      <div class="bottom-subtitle text-center" [innerHTML]="secondGoalTitle"></div>
    </div>
  </div>

  <h1 class="display-6 mt-30">Select your plan:</h1>

  <div 
  class="gift-box col-12 col-lg-6 border-radius d-flex align-items-center" 
  [ngClass]="{ 'bg-main-color': placement === 'students_quiz_discount', 'bg-orange-color': placement === 'students_quiz' }"
  (click)="payButtonClicked()"
  >
    <div class="gift-icon"></div>
    <div *ngIf="placement === 'students_quiz_discount'" class="gift-label">Get your personal plan with a <span class="crossed greyed">{{initialDiscountTitle}}</span> {{discountTitle}} discount</div>
    <div *ngIf="placement === 'students_quiz'" class="gift-label">
      <div>{{discountTitle}} <span class="fw-normal">discount is reserved for:</span></div>
      <div>{{ timer }}</div>
    </div>
  </div>

  <div class="col-12 mt-25" #topCheckout>
    <app-checkout 
      [(selectedProduct)]="selectedProduct"
      #checkout 
      [placement]="placement"
      [email]="email" 
      [name]="name"
      [isDisclaimerShown]="false"
      [stripeCustomerId]="stripeCustomerId"
      [insufficientFundsAction]="getInsufficientFundsAction()"
      (canceledPurchase)="onPurchaseCanceled()"
      (purchaseButtonTitle)="handlePurchaseButtonTitle($event)"
      (discountTitle)="handleDiscountTitle($event)">
    </app-checkout>
  </div>

  <div class="mt-20 d-flex flex-column align-items-center">
    <div class="payment-promo">Safe, secure and easy way to pay</div>
    <div class="options-logos"></div>
  </div>

  <div class="row align-items-center mt-30">
    <div class="col-lg-7 mt-20">
      <h2 class="display-6">What you get with Peech?</h2>
      <div class="mt-15 ps-20 text-list">
          <div>Unlimited listening with lifelike voices</div>
          <div>Multiple formats: PDFs, eBooks, Scans, Docs, Web Articles</div>
          <div>Speed control to match your learning pace</div>
          <div>Accurately pronounces technical terms</div>
          <div>60+ languages to enhance efficiency</div>
          <div>Create custom playlists for focused work sessions</div>
          <div>Boost productivity by listening anytime, anywhere</div>
      </div>
        <!-- <h1 class="display-6" [innerHTML]="title"></h1>
        <div class="lead">Save hours and get your eyes some rest, in your spare time</div> -->
    </div>
    <div class="col-lg-5 d-flex justify-content-center mt-20 mb-20">
      <img class="top-image" src="assets/img/top2.png">
    </div>
  </div>
  
  <!-- <div class="row mt-30">
      <h1 class="display-6">The world’s first mobile app <span class="main-color">built for listening with users in mind</span></h1>
  </div>
  <div class="row align-items-center mt-20">
      <div class="col-lg-6 col-sm-12 mt-20">
          <div class="lead">What you get with Peech?</div>
      </div>
  </div> -->

  <div class="row mt-30">
      <h1 class="display-6">We are ready to answer you questions</h1>
  </div>
  <div class="col-lg-6 col-md-12 mt-25">
      <mat-accordion class="tagus-basic-expansion mb-20">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                Why do I need the Peech app?
              </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Peech turns your books, articles, and notes into natural-sounding audio, so you can consume the content anytime, anywhere without being glued to a screen. Whether you're commuting, exercising, or relaxing, Peech helps you reduce eye strain, save time, and stay productive—no matter how busy your day gets.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
              How easy is it to convert my reading materials into audio?
            </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Extremely easy! Simply upload your documents or links, and Peech will handle the rest.</p>
    </mat-expansion-panel>
          <mat-expansion-panel>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      How do I cancel my account?
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <p>It's easy! Just log into your account here, go to "Settings", and click the Cancel button.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                    How do I get access to the app?
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <p>After you fill in your email address, a mail with a temporary password and download link to App Store will be in your inbox. Just install the app, Sign In with your email.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      Can it pronounce difficult words, like "Mesothelioma" or "Diphenhydramine"?
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Yep. If our audio ever mispronounces a word, just let us know and we'll fix it.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      I only want to listen to useful content of the web article. How do I do that?
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <p>We pull only useful text from any web page or document!</p>
          </mat-expansion-panel>
      </mat-accordion>
  </div>

  <h1 class="display-6 mt-30">Don’t Just Take Our Word for It</h1>

  <div class="d-flex flex-wrap justify-content-center w-100 mt-20">
    <app-user-review
      name="Alena Mango"
      avatarUrl="assets/img/avatars/1.png"
      message="I’m preparing for my first marathon and spend hours training every week. Before Peech, I struggled to keep up with my readings. Now, I listen to my lectures while running, so I never lose time and my retention has improved dramatically!"
    ></app-user-review>

    <app-user-review
      name="Justin Geidt"
      avatarUrl="assets/img/avatars/4.png"
      message="Balancing my engineering workload and personal life felt impossible before Peech. Now, I use it daily to turn my technical readings into audio, and I’ve gained so much extra time for myself."
    ></app-user-review>

    <app-user-review
      name="Glenn Levin"
      avatarUrl="assets/img/avatars/3.png"
      message="As a doctor, there’s so much to read every day. Peech helped me convert my textbooks into audio, which allowed me to study even while doing errands. My productivity has never been higher!"
    ></app-user-review>
  </div>

  <button mat-flat-button class="tagus pay-button pulsing" (click)="payButtonClicked()">{{purchaseButtonTitle}}</button>

  <h1 class="display-6 mt-30">We've prepared a few audio samples for you</h1>
  <div class="row align-items-center">
      <div class="col-12 light-green-color border-radius p-25 d-flex flex-column">
          <h5 class="display-6">Just Hit <span class="ri-play-fill main-color"></span> to Try</h5>
          <p class="lead">🎓 For Work: Experience how Peech makes articles and academic papers easy to absorb.</p>
          <app-audio-track #audioTrackRef (onPlayed)="onPlay($event)" [data]="trackStudy"></app-audio-track>
          <p class="mt-20 lead">🎧 For Leisure: Enjoy your favorite articles or books, effortlessly converted into lifelike audio.</p>
          <app-audio-track #audioTrackRef (onPlayed)="onPlay($event)" [data]="trackLeisure"></app-audio-track>
          <p class="mt-20 lead">🌍 For Language Learning: Improve your foreign language skills with accurate pronunciation and natural intonation.</p>
          <app-audio-track #audioTrackRef (onPlayed)="onPlay($event)" [data]="trackLanguage"></app-audio-track>
      </div>
  </div>


  <!-- <h1 class="display-6 mt-20">Try <span class="main-color">Peech Premium</span> with introductory discount</h1>
  <div class="subscription-info">
    <div class="subscription-text">Enjoy unlimited access for selected period. Love it and keep investing in yourself, or simply cancel before the period ends so you won’t be charged repeatedly.</div>
  </div>

  <button mat-flat-button class="tagus pay-button" (click)="payButtonClicked()">{{purchaseButtonTitle}}</button> -->

  <h1 class="display-6 mt-30">Join Millions of <span class="main-color">Successful Learners</span> with Peech</h1>
  <div class="d-flex flex-wrap justify-content-center w-100 mt-20 gap-15">
    <div class="stat d-flex align-items-center">
      <div class="stats-icon"></div>
      <div class="d-flex flex-column">
        <div class="stats-title"><span class="highlight">20</span> Million</div>
        <div class="stats-text">Users on all platforms</div>
      </div>
    </div>
    <div class="stat d-flex align-items-center">
      <div class="stats-icon"></div>
      <div class="d-flex flex-column">
        <div class="stats-title"><span class="highlight">4.8</span> Stars</div>
        <div class="stats-text">Average ratings on App Store</div>
      </div>
    </div>
    <div class="stat d-flex align-items-center">
      <div class="stats-icon"></div>
      <div class="d-flex flex-column">
        <div class="stats-title"><span class="highlight">91%</span> of Peech members</div>
        <div class="stats-text">Create a better reading habit*</div>
      </div>
    </div>
  </div>

  <h1 class="display-6 mt-30">Select your plan:</h1>

  <div class="col-12 mt-15">
    <app-checkout 
      [(selectedProduct)]="selectedProduct"
      #checkout 
      [placement]="placement"
      [email]="email" 
      [name]="name"
      [stripeCustomerId]="stripeCustomerId"
      [isDisclaimerShown]="false"
      [insufficientFundsAction]="getInsufficientFundsAction()"
      (canceledPurchase)="onPurchaseCanceled()"
      (purchaseButtonTitle)="handlePurchaseButtonTitle($event)"
      (discountTitle)="handleDiscountTitle($event)">
    </app-checkout>
  </div>

  <div class="hint mt-20 fs-14">*Based on survey data from Peech users</div>

  <div class="muted-color fs-12 mt-20" *ngIf="selectedProduct">We’ve automatically applied discount to your first subscription price. You will be charged {{selectedProduct.priceLabel}}. Please note that your subscription will be automatically renewed at full price of ${{selectedProduct.recurringPriceUsd}} at the end of chosen subscription term. Terms and Conditions apply. If you want to manage your subscription, you may do so via your personal account.</div>

  <div class="muted-color mt-20 mb-30 fs-12"><a target="_blank" href="https://www.getpeech.com/terms">Terms & Conditions</a> and <a target="_blank" href="https://www.getpeech.com/privacy">Privacy Policy</a></div>
</div>
