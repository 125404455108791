import * as amplitude from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, interval, take, takeWhile, tap } from 'rxjs';
import { Placement, Product } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { AudioTrack, AudioTrackComponent } from 'src/app/shared/audio-track/audio-track.component';
import { CheckoutComponent } from 'src/app/shared/checkout/checkout.component';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss']
})
export class PaywallComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('topCheckout') topCheckout: ElementRef;
  @ViewChildren('checkout') checkoutComponents: QueryList<CheckoutComponent>;
  @ViewChildren('audioTrackRef') audioTracks!: QueryList<AudioTrackComponent>;

  selectedProduct: Product;

  discountTitle: string = '60%';
  initialDiscountTitle: string = '';
  timer: string = '10:00';

  email: string | undefined;
  name: string | undefined;
  stripeCustomerId: string | undefined;

  purchaseButtonTitle = 'Continue';
  topImageClassName: string;

  topTitle: string;

  trackLeisure: AudioTrack;
  trackStudy: AudioTrack;
  trackLanguage: AudioTrack;

  topLevelTitle: string;
  topImprovementTitle: string;

  secondGoalTitle: string;

  placement: Placement = 'students_quiz';

  private isActive: boolean = true; //to kill the subscriptions

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private flowService: OnboardingFlowService, 
    private pixel: EventsService) {}

  ngOnInit(): void {
    const audio = this.flowService.getUserAudioTracks();
    const comparisonData = this.flowService.getPaywallComparisonLabels();

    this.topTitle = this.flowService.getTopTitle();
    this.topImageClassName = this.flowService.getPaywallMainImageClassName();
    this.name = this.flowService.getName();
    this.stripeCustomerId = this.flowService.getStripeCustomerId();
    this.secondGoalTitle = this.flowService.getSecondaryGoal();
    this.trackLanguage = audio.language;
    this.trackLeisure = audio.leisure;
    this.trackStudy = audio.subject;
    this.topLevelTitle = comparisonData.level;
    this.topImprovementTitle = comparisonData.graph;
    
    this.authService.user$.pipe(
      take(1),
      tap(user => this.email = user?.email || undefined),
    ).subscribe();    
  }

  ngAfterViewInit(): void {
    this.startTimer();

    this.pixel.track('checkout_page_opened');
    track('web_checkout_view');
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  payButtonClicked(): void {
    this.checkoutComponents.first.purchaseButtonClicked();
  }

  topButtonClicked(): void {
    this.scrollToCheckout();
  }

  handleDiscountTitle(title: string) {
    this.discountTitle = title;

    if (!this.initialDiscountTitle) {
      this.initialDiscountTitle = title;
    }
  }

  handlePurchaseButtonTitle(title: string) {
    this.purchaseButtonTitle = title;
  }

  getInsufficientFundsAction(): (() => void) | undefined {
    if (this.placement === 'students_quiz_discount') {
      //nothing to offer already
      return;
    }

    return () => {
      //TODO: maybe show another animation in this case
      this.switchToDiscount(`We see that there are insufficient funds, but at Peech we are trying to support as many people as possible. That's why we are offering you an additional discount`);
    };
  }

  onPlay(currentTrack: AudioTrackComponent): void {
    this.audioTracks.forEach(trackComponent => {
      if (trackComponent !== currentTrack) {
        trackComponent.pause();
      }
    });
  }

  onPurchaseCanceled() {
    if (this.placement === 'students_quiz_discount') {
      //nothing to offer more
      return;
    }

    this.switchToDiscount('We want you to succeed, which is why we are offering you an additional discount');
  }

  private scrollToCheckout() {
    this.topCheckout.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  private switchToDiscount(message: string) {
    this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Special Discount Unlocked',
        message,
        okButtonTitle: 'Get My Discount',
        rive: {
          fileName: 'gift_box',
          animations: ['Timeline 1'],
          ratio: 1.1 / 1,
        },
      }
    })
      .afterClosed()
      .pipe(
        tap(() => {
          this.placement = 'students_quiz_discount';
          amplitude.track('web_discount_shown');
          this.scrollToCheckout();
        }),
      )
      .subscribe();
  }

  private startTimer(): void {
    const secs = 10 * 60;

    interval(1000)
      .pipe(
        filter(passedSecs => passedSecs > 0),
        tap((passedSecs) => {
          const leftSecs = 60 - (passedSecs % 60);
          const leftSecsLabel = leftSecs > 9 ? `${leftSecs}` : `0${leftSecs}`;
          const minsLeft = Math.floor((secs - passedSecs) / 60);

          this.timer = `${minsLeft}:${leftSecsLabel}`;
        }),
        takeWhile(() => this.isActive),
      )
      .subscribe();
  }
}
