<div
    class="pt-5 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="row align-items-center">
        <div class="col-lg-5 d-flex justify-content-center">
            <img class="top-image" src="assets/img/top2.png">
        </div>
        <div class="col-lg-7 mt-20 mb-20">
            <h1 class="display-3">Convert anything into beautiful audio</h1>
            <div class="lead">Welcome to Peech! Reading can be tough and time-consuming, but listening is effortless. <br>Peech turns any text file, pdf, real book, or web article into audio. <br>Save hours, enhance your productivity, retain more of what you learn, and give your eyes some rest.</div>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">What people think <span class="main-color">about us</span></h1>
    </div>
    <div class="row reviews align-items-stretch justify-content-center mt-20">
        <app-user-review
            name="Jayati S"
            message="Having an app that converts all my reading into voice is just so convenient and amazing"
            avatarUrl="assets/img/avatars/1.png"
        ></app-user-review>
        <app-user-review
            name="Lauren Fratamico"
            message="I always have stacks of papers I want to get through, and it's been so nice to finally find an app that allows me to listen to them on the go with a naturalistic voice. Would recommend!"
            avatarUrl="assets/img/avatars/2.png"
        ></app-user-review>
        <app-user-review
            name="Mary Ballard-Johansson"
            message="What a great app! The reading voice is pleasant and understandable. Even on the harder words. Having the text available to read along helps also. Naturally, as with any new app, there are bugs. But support is readily available. Highly recommend this for anyone doing research or just keeping up with the latest articles."
            avatarUrl="assets/img/person3.webp"
        ></app-user-review>
        <app-user-review
            name="AlexP1799"
            message="This app is a MUST for anyone in academics. I’ve only had it for a couple of weeks and I’m already sold. I’m in grad school at the moment and almost all of our reading are academic articles. It takes me ages to get through them every week and by the end it feels like my eyes are going to fall out of my head."
            avatarUrl="assets/img/avatars/3.png"
        ></app-user-review>
        <app-user-review
            name="Watson Chiwambo"
            message="Amazing app to help you study especially if you don't have much time due to work, family and other things."
            avatarUrl="assets/img/avatars/4.png"
        ></app-user-review>
        <app-user-review
            name="Jenny0987"
            message="This app is such a great concept! Makes it easy to do everything I want to do all in one. Great way to multi task. This app would be so useful with school. It can read to you while you’re doing work!"
            avatarUrl="assets/img/avatars/7.png"
        ></app-user-review>
        <app-user-review
            name="Erin Barnes"
            message="The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend."
            avatarUrl="assets/img/avatars/5.png"
        ></app-user-review>
        <app-user-review
            name="Alisson X"
            message="It is the perfect application to study without having to stop doing your things, I put my headphones on and it saves me a lot of time. Totally recommended!"
            avatarUrl="assets/img/avatars/6.png"
        ></app-user-review>
    </div>
</div>