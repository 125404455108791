<div *ngIf="option.emoji" class="icon">{{ option.emoji }}</div>
<div *ngIf="option.imageFilePath" class="image w-100" [ngStyle]="{ 'background-image': 'url(' + option.imageFilePath + ')' }"></div>
<canvas
    *ngIf="option.rive"
    #animation
    class="w-100" 
    width="480"
    [height]="480 / option.rive.ratio"
    >
</canvas>
<div *ngIf="option.title" class="title">{{ option.title }}</div>
