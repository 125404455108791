import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum BillingPeriod {
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  SemiYear = 'semiyear',
  Year = 'year',
};

export interface Product {
  id: number;
  code: string;
  billingPeriod: BillingPeriod;
  billingPeriodDays: number;

  trialDays: number;
  
  setupFeePriceUsd?: number;

  introductoryPriceUsd?: number;

  recurringPriceUsd: number;

  dailyPriceUsd: number;
  dailyIntroductoryPriceUsd?: number;

  environment: string; //keyof typeof subscriptionEnvironments;

  isDefault: boolean;

  discount?: {
    amount: number;
    units: string;
    duration: string;
  };
};

export interface SubscriptionState {
  isSubscribed: boolean;
  isActivePromo: boolean;
  expireDate: Date | null;
  type: string;
  quotas?: Record<string, number>;
}

export interface User {
  userId: number;
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface UserDetails extends User {
  isEligibleForTrial: boolean;
  subscriptionState: SubscriptionState;
  webProduct?: Product;
}

export type Placement = 'discount' | 'students_quiz' | 'students_quiz_discount';

@Injectable({
  providedIn: 'root'
})
export class ApiService {  
  private readonly baseUrl = environment.serviceUrl;

  constructor(private http: HttpClient) {}

  getProducts(placement: Placement) {
    const url = `${this.baseUrl}/products?placement=${placement}`;

    return this.http.get<{ products: Product[] }>(url).pipe(map(response => response.products))
  }

  createStripeCustomer(email: string | null, extras: object | null) {
    return this.http.post<{ customerId: string; }>(`${this.baseUrl}/stripe/create-customer`, {
      email,
      ...extras, // to contain amplitudeDeviceId and/or name
    });
  }

  createStripeSubscription(productId: number, email?: string, customerId?: string, name?: string) {
    return this.http.post<{ clientSecret: string; isSetupIntent: boolean; stripeCustomerId: string; }>(`${this.baseUrl}/stripe/create-subscription`, {
      productId,
      email,
      customerId,
      name
    });
  }

  cancelStripeSubscription() {
    return this.http.post(`${this.baseUrl}/stripe/cancel-subscription`, null);
  }

  getUserDetails() {
    return this.http.get<UserDetails>(`${this.baseUrl}/users/details`);
  }

  identifyUser(id: string) {
    return this.http.get<User>(`${this.baseUrl}/users/identify?id=${id}`);
  }
}
